import {errorMessages} from "./data";

let errorRule = '';

export const isFilledOut = (value) => {
    return Boolean(value);
};

export const isEmail = (value) => {
    let regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let result = regex.exec(value);
    
    if (!result) {
        return false;
    }
    
    return (result[0] === value);
};

export const hasMinLength = (value, length) => {
    return (value.length >= length);
};

export const isNumber = (value) => {
    return !isNaN(value);
};

export const validate = (value, rules = [], data = []) => {
    for (let i = 0; i < rules.length; i++) {
        let key = Object.keys(rules[i])[0];
        
        if (typeof rules[i] == 'object' && key.includes(':')) {
            let rule = key.split(':')[0];
            let toColumn = key.split(':')[1];
            
            if (rule === 'ifEmpty' && ! isFilledOut(data[toColumn])) {
                return validate(value, rules[i][key], data);
            }
        }
        
        if (rulesMap[rules[i]] && !rulesMap[rules[i]](value)) {
            errorRule = rules[i];
            return false;
        }
    }
    
    return true;
};

export const validateForm = (form, data, fields) => {
    for (let field in fields) {
        if (fields.hasOwnProperty(field)) {
            let rules = fields[field];
            
            if (!validate(data[field], rules, data)) {
                form.setErrorMessage(field, getErrorMessage());
            }
        }
    }
};

export const getErrorMessage = () => {
    return errorMessages[errorRule] || '[error] ' + errorRule;
};

const rulesMap = {
    email: isEmail,
    required: isFilledOut,
    number: isNumber,
};