import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import ButtonGroup from "../../button-group";
import Button from "../../../atoms/button";
import InputStack from "../../input-stack";

class CustomerEdit extends React.Component {

  static defaultProps = {
    customer: {},
    onCancel: () => {},
    onSave: () => {}
  };

  constructor(props) {

    super(props);
    this.state = {customer: this.props.customer};

    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {

    if (this.props.customer && (prevProps.customer !== this.props.customer)) {
      this.fetchCustomer();
    }
  }

  componentDidMount() {

    this.fetchCustomer();
  }

  fetchCustomer() {

    http.request({
      url: API_HOST+API_ROOT+'customers/'+this.props.customer.id
    }, true).then(response => {
      this.setState({customer: response.result});
    });
  }

  validateForm(form, data) {

    validateForm(form, data, {
      email: ['email', 'required'],
      first_name: ['required'],
      last_name: ['required'],
      address_street: ['required'],
      address_street_number: ['required'],
      address_postal_code: ['required'],
      address_city: ['required'],
      address_country: ['required'],
    });
  }

  onSubmit(customerData) {

    http.patch(API_HOST+API_ROOT+'customers/'+this.props.customer.id, customerData,
      true).then(response => {
        if (response.success) {
          this.props.onSave(response.result);
        }
    }).catch(error => {
      alert(error);
    });
  }

  render() {

    if (! this.state.customer) {
      return;
    }

    return (
      <React.Fragment>
        <Form validate={(form, data) => this.validateForm(form, data)}
              onSubmit={(data) => this.onSubmit(data)}
              showSubmitButton={false}
              ref={this.form}
        >
          <InputStack modifiers={'alternate-direction'} title={'Personal information'}>
            <TextInput name="email" label="Email Address" default={this.state.customer.email} disabled={true} showRequiredIndicator={true} />
            <InputStack>
              <TextInput name="first_name" label="First name" default={this.state.customer.first_name} showRequiredIndicator={true} />
              <TextInput name="last_name" label="Last name" default={this.state.customer.last_name} showRequiredIndicator={true} />
            </InputStack>
            <TextInput name="telephone" label="Telephone" default={this.state.customer.telephone}/>
          </InputStack>

          <InputStack modifiers={'alternate-direction'} title={'Invoice info'}>
            <InputStack>
              <TextInput name="company_name" label="Company name" default={this.state.customer.company_name} />
              <TextInput name="company_vat_number" label="VAT" default={this.state.customer.company_vat_number} />
            </InputStack>

            <TextInput name="address_street" label="Street" default={this.state.customer.address_street} showRequiredIndicator={true} />
            <TextInput name="address_street_number" label="Number" default={this.state.customer.address_street_number} showRequiredIndicator={true} />
            <TextInput name="address_postal_code" label="Postal code" default={this.state.customer.address_postal_code} showRequiredIndicator={true} />
            <TextInput name="address_city" label="City" default={this.state.customer.address_city} showRequiredIndicator={true} />
            <TextInput name="address_country" label="Country" default={this.state.customer.address_country} showRequiredIndicator={true} />
          </InputStack>
        </Form>
        <ButtonGroup>
          <Button text="Cancel" onClick={() => this.props.onCancel()} modifiers={['large', 'outline']} />
          <Button text="Proceed" type="submit" onClick={() => this.form.current.submit()} modifiers={['large']} />
        </ButtonGroup>
      </React.Fragment>
    );
  }
}

export default CustomerEdit;