import './index.scss';

import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../state/modules/resources";

import React from 'react';

import TextInput from "../../atoms/text-input";

const perPageOptions = [8,10,15,25,50];

class Pagination extends React.Component {

  static defaultProps = {
    listId: null,
    model: null
  };

  goTo(page) {

    this.props.updateList(this.props.listId, {
      pagination: {
        currentPage: page
      }
    });
  }

  setLimit(amount) {

    this.props.updateList(this.props.listId, {
      limit: amount
    });
  }

  getPaginationData() {

    return this.props.lists[this.props.listId] ? this.props.lists[this.props.listId].pagination : null;
  }

  getItems() {

    let data = this.getPaginationData();
    let pages = [];

    if (data) {

      if (data.currentPage - 1 > 0) {
        pages.push(
          <li key={'first'} className="pagination__item">
            <button onClick={e => this.goTo(Math.max(1, data.currentPage - 1))}>
              ←
            </button>
          </li>
        );
      }

      if (data.pageCount > 5) {

        if (data.currentPage < 4) {

          for (let i = 1; i <= 4; i++) {
            pages.push(
              <li className={"pagination__item" + (i === data.currentPage ? ' pagination__item--active' : '')} key={i}>
                <button onClick={() => {
                  this.goTo(i)
                }}>
                  {i}
                </button>
              </li>
            );
          }

        } else {

          pages.push(
            <li className={"pagination__item" + (1 === data.currentPage ? ' pagination__item--active' : '')} key={1}>
              <button onClick={() => {
                this.goTo(1)
              }}>
                1
              </button>
            </li>
          );

          pages.push(
            <li className={"pagination__item"} key={'divider'}>
              <button onClick={() => {
                this.goTo(Math.max(2, data.currentPage - 4))
              }}>
                ...
              </button>
            </li>
          );

          if ((data.currentPage + 1) > data.pageCount) {
            pages.push(
              <li className={"pagination__item"} key={data.currentPage - 2}>
                <button onClick={() => {
                  this.goTo(data.currentPage - 2)
                }}>
                  {data.currentPage - 2}
                </button>
              </li>
            );
          }

          pages.push(
            <li className={"pagination__item"} key={data.currentPage - 1}>
              <button onClick={() => {
                this.goTo(data.currentPage - 1)
              }}>
                {data.currentPage - 1}
              </button>
            </li>
          );

          pages.push(
            <li className='pagination__item pagination__item--active' key={data.currentPage}>
              <button onClick={() => {
                this.goTo(data.currentPage)
              }}>
                {data.currentPage}
              </button>
            </li>
          );

          if (data.currentPage + 1 <= data.pageCount) {
            pages.push(
              <li className='pagination__item' key={data.currentPage + 1}>
                <button onClick={() => {
                  this.goTo(data.currentPage + 1)
                }}>
                  {data.currentPage + 1}
                </button>
              </li>
            );
          }
        }

        if (data.currentPage < data.pageCount - 1) {

          pages.push(
            <li className='pagination__item' key={'divider2'}>
              <button onClick={() => {
                this.goTo(data.currentPage + 4)
              }}>
                ...
              </button>
            </li>
          );

          pages.push(
            <li className='pagination__item' key={data.pageCount}>
              <button onClick={() => {
                this.goTo(data.pageCount)
              }}>
                {data.pageCount}
              </button>
            </li>
          );
        }
      }

      if (data.currentPage + 1 <= data.pageCount) {
        pages.push(
          <li className='pagination__item' key={'next'}>
            <button onClick={() => {
              this.goTo(data.currentPage + 1)
            }}>
              →
            </button>
          </li>
        );
      }
    }

    return pages;
  }

  getPerPageOptions(defaultPerPage) {

    perPageOptions.includes(defaultPerPage) || perPageOptions.push(defaultPerPage);
    perPageOptions.sort((a,b) => a-b);

    return perPageOptions.map((option, key) => <option key={key} value={option}>{option}</option>);
  }

  render() {

    let list = this.props.lists[this.props.listId];

    if (! list) {
      return null;
    }

    let { items, pagination, limit } = list;

    if (! items.length || ! pagination) {
      return null;
    }

    let data = this.getPaginationData();

    return (
      <div className="pagination">
        <div className="pagination__section">
          <label className="pagination__label">Per page</label>
          <select className="pagination__select" onChange={e => this.setLimit(e.target.value)} defaultValue={limit ? limit : pagination.defaultPerPage}>
              {this.getPerPageOptions(pagination.defaultPerPage)}
          </select>
        </div>
        <div className="pagination__section">
          <ul className="pagination__list">
            {this.getItems()}
          </ul>
        </div>
        <div className="pagination__section">
          <TextInput modifiers="small" placeholder="Go to" onChange={value => this.goTo(Math.max(1, Math.min(value, data.pageCount)))} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);