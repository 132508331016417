import './index.scss';

import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../state/modules/resources";

import React from 'react';

class Header extends React.Component
{
  static defaultProps = {
    listId: null,
    children: []
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSorts: {}
    };
  }

  componentDidMount() {
    this.setState({activeSorts: this.props.lists[this.props.listId].sort});
  }

  handleChange(field, value, multiple) {

    let activeSorts = this.state.activeSorts;

    if (! multiple) {
      activeSorts = {}
    }

    activeSorts[field] = value;

    this.props.updateList(this.props.listId, {
      sort: activeSorts
    });
  }

  getHeaderItem(component, key) {

    let TagName = component.type;

    return <TagName
      {...component.props}
      key={key}
      sorting={this.state.activeSorts[component.props.field] || null}
      onChange={(field, value, multiple) => this.handleChange(field, value, multiple)}
    />;
  }

  render() {

    return (
      <span className={'header'}>
        {this.props.children.map((child, index)  => {
          return this.getHeaderItem(child, index);
        })}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);