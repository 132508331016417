import './index.scss';

import React from 'react';

class BarChart extends React.Component {

  static defaultProps = {
    data: [],
    minValue: 0,
    stepSize: 1000,
    showProjectedValue: true,
    onItemSelect: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      maxValue: 0,
      maxYValue: 0
    };
  }

  componentDidMount() {

    this.processData();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.data !== this.props.data) {
      this.processData();
    }
  }

  processData() {

    let maxValue = 0;

    this.props.data.forEach(data => {
      if (data.projectedValue) {
        maxValue = Math.max(data.projectedValue, maxValue);
        return;
      }

      maxValue = Math.max(data.value, maxValue);
    });

    this.setState({
      maxValue: maxValue,
      maxYValue: this.roundUp(maxValue, this.props.stepSize) + this.props.stepSize
    });
  }

  valueToPercentage(value) {

    return (value / this.state.maxYValue) * 100;
  }

  getYAxisValues() {

    let yAxis = [];

    let currentMaxYValue = this.props.minValue;

    while ((currentMaxYValue+this.props.stepSize) < this.state.maxValue) {
      currentMaxYValue = currentMaxYValue+this.props.stepSize;

      yAxis.push(
        <span key={currentMaxYValue} className="bar-chart__y-label">
          {currentMaxYValue}
        </span>
      );
    }

    return yAxis.reverse();
  }

  roundUp(num, precision) {
    num = parseFloat(num);
    if (!precision) return num;
    return Math.ceil(num / precision) * precision;
  };

  render() {

    let yAxis = this.getYAxisValues();

    let bars = [];

    this.props.data.forEach((data, i) => {

      bars.push(
        <div className="bar-chart__bar" key={i} onClick={e => this.props.onItemSelect(data.label)}>
          <div className="bar-chart__bar-value" style={{height: this.valueToPercentage(data.value)+'%'}}>
            <div className="bar-chart__bar-tooltip">
              {data.tooltip ? (
                data.tooltip.map((t, index) => <span key={index} className="bar-chart__bar-tooltip-value">{t}</span>)
                ) : (
                <span className="bar-chart__bar-tooltip-value">{data.value}</span>
              )}
            </div>
          </div>
          {data.projectedValue && (
            <span className="bar-chart__bar-projected-value" style={{
              height: this.valueToPercentage(data.projectedValue)+'%'
            }}>&nbsp;</span>
          )}
          <span className="bar-chart__bar-label">
            {data.label}
          </span>
        </div>
      );
    });

    return (
      <div className="bar-chart">
        <div className="bar-chart__y-axis">
          <div className="bar-chart__y-label">{this.state.maxYValue}</div>
          {yAxis}
          <div className="bar-chart__y-label">{this.props.minValue}</div>
        </div>
        <div className="bar-chart__bars">
          {bars}
        </div>
      </div>
    );
  }
}

export default BarChart;