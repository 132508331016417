import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import ui from "../../../../util/ui";
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";
import HiddenInput from "../../../atoms/hidden-input";

class ClientOrderCreate extends React.Component {

  static defaultProps = {
    onSave: () => {}
  };

  constructor(props) {

    super(props);

    this.form = React.createRef();
  }

  validateForm(form, data) {

    validateForm(form, data, {
      title: ['required'],
      advance: ['required', 'number'],
      total_price: ['required', 'number'],
      email: ['required', 'email']
    });
  }

  onSubmit(clientOrderData) {

    clientOrderData.current_price = clientOrderData.advance;

    http.post(API_HOST+API_ROOT+'client-orders', clientOrderData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      ui.notify('There was an error, client order couldn\'t be created');
    });
  }

  render() {

    return (
        <Form
            ref={this.form}
            validate={(form, data) => this.validateForm(form, data)}
            onSubmit={(data) => this.onSubmit(data)}
            submitButtonText={'Create'}
        >
          <TextInput name="title" label="Title" showRequiredIndicator={true} focus={true} />
          <InputStack>
            <TextInput name="advance" label="Advance" showRequiredIndicator={true} />
            <TextInput name="total_price" label="Price" showRequiredIndicator={true} />
          </InputStack>
          <TextInput name="email" label="Email" showRequiredIndicator={true} />
          <TextInput name="note" label="Note" />
          <HiddenInput name="status" value="awaiting_order_create" />
        </Form>
    );
  }
}

export default ClientOrderCreate;