import './index.scss';

import React from 'react';
import { photoSrc } from "../../../util/media";
import modifiers from "../../../util/components";

class Photo extends React.Component {

  static defaultProps = {
    modifiers: [],
    src: ''
  };

  render() {

    return (
      <div className={'photo'+modifiers('photo', this.props.modifiers)}>
        <img src={photoSrc(this.props.src)} />
      </div>
    );
  }
}

export default Photo;