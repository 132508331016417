import './index.scss';

import React from 'react';

import IconButton from "../../atoms/icon-button";
import Window from "../window";

class Popup extends React.Component {

  static defaultProps = {
    children: [],
    footer: [],
    header: [],
    focus: false,
    fullscreen: false,
    padded: true,
    defaultPosition: {x: 0, y: 0},
    toggle: false,
    onClose: () => {},
    onFocus: () => {},
    onDragStop: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      isDragging: false,
      position: this.props.defaultPosition
    };
  }

  componentDidUpdate(prevProps) {

    if (! prevProps.focus && (prevProps.focus !== this.props.focus)) {
      this.props.onFocus();
    }
  }

  handleDragStart(e) {

    this.setState({
      isDragging: true
    });
  }

  handleDragStop(e, data) {

    const position = {x: data.x, y: data.y};

    this.props.onDragStop(position);

    this.setState({
      isDragging: false,
      position: position,
    });
  }

  renderHeader() {

    let closeButton = (
      <IconButton key={'close'} iconType="close" onClick={this.props.onClose} modifiers="alt" />
    );

    if (! this.props.header.length) {
      return [
        this.props.header,
        closeButton
      ];
    }

    return this.props.header.concat([closeButton]);
  }

  render() {

    return (
      <div className={'popup'+(this.props.focus ? ' popup--focus' : '')+(this.state.isDragging? ' popup--dragging' : '')} onMouseDown={this.props.onFocus.bind(this)}>
        <Window
          draggable={true}
          fullscreen={this.props.fullscreen}
          defaultPosition={this.state.position}
          onDragStart={this.handleDragStart.bind(this)}
          onDragStop={this.handleDragStop.bind(this)}
          title={this.props.title}
          footer={this.props.footer}
          modifiers={['depth', 'sticky', (this.props.padded ? 'padded' : 'non-padded')]}
          header={this.renderHeader()}
        >
          {this.props.children}
        </Window>
      </div>
    );
  }
}

export default Popup;