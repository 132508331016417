import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../../state/modules/resources";

import React from 'react';
import time from "../../../../util/time";

import Window from "../../window/index";
import List from "../../list/index";
import Button from "../../../atoms/button";
import ButtonGroup from "../../button-group";
import CustomerListItem from "../customer-list-item";
import NumberWidget from "../../number-widget";
import Pagination from "../../pagination";
import Grid from "../../grid";
import Search from "../../search";

class CustomerIndex extends React.Component {

  static defaultProps = {
    onView: () => {},
    onEdit: () => {},
    onCreate: () => {}
  };

  componentDidMount() {

    this.props.updateList('customers-total', {
      model: 'customers'
    }, true);

    this.props.updateList('customers-month-total', {
      model: 'customers',
      filters: {
        filter_created: [
          {key: 'gte:'+time.firstDayOfMonthUnixTime()}
        ]
      }
    }, true);

    this.props.updateList('customers-today-total', {
      model: 'customers',
      filters: {
        filter_created: [
          {key: 'gte:'+time.todayUnixTime()}
        ]
      }
    }, true);
  }

  showWidgets() {

    if (! this.props.lists['customers']) {
      return true;
    }

    return (! this.props.lists['customers'].search && this.props.lists['customers'].pagination.currentPage === 1);
  }

  render() {

    let widgets;

    let lists = this.props.lists;
    let totalCustomers = 0;
    let totalMonthCustomers = 0;
    let totalTodayCustomers = 0;

    if (lists['customers-total'] && lists['customers-total'].metadata && lists['customers-total'].metadata.count) {
      totalCustomers = lists['customers-total'].metadata.count;
    }

    if (lists['customers-month-total'] && lists['customers-month-total'].metadata && lists['customers-month-total'].metadata.count) {
      totalMonthCustomers = lists['customers-month-total'].metadata.count;
    }

    if (lists['customers-today-total'] && lists['customers-today-total'].metadata && lists['customers-today-total'].metadata.count) {
      totalTodayCustomers = lists['customers-today-total'].metadata.count;
    }

    if (this.showWidgets()) {

      widgets = (
        <Grid cols="1fr 1fr 1fr">
          <NumberWidget modifiers={'depth'} value={totalCustomers} title="Total customer count"/>
          <NumberWidget modifiers={'depth'} value={totalMonthCustomers} title="This month customer count"/>
          <NumberWidget modifiers={'depth'} value={totalTodayCustomers} title="Today customer count"/>
        </Grid>
      );
    }

    return (
      <Window
        title="Customers"
        header={
          <ButtonGroup>
            <Search listId={'customers'} />
            <Button text="Create customer" onClick={() => this.props.onCreate()} />
          </ButtonGroup>
        }
      >
        <Grid rows="auto 1fr">
          {widgets}
          <Window modifiers={'depth'} footer={<Pagination listId={'customers'} model={'customers'} />}>
            <List id={'customers'} model={'customers'} onItemClick={item => this.props.onView(item)}>
              <CustomerListItem onEdit={(customer) => this.props.onEdit(customer)} onView={customer => this.props.onView(customer)}/>
            </List>
          </Window>
        </Grid>
      </Window>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = { updateList };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerIndex);