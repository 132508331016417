import React from 'react';

import Label from "../../../atoms/label";
import Address from "../../address";
import CustomerCard from "../customer-card";

class CustomerListItem extends React.Component
{
  static defaultProps = {
    model: {},
    onEdit: () => {},
    onView: () => {}
  };

  render() {

    let vat;
    let companyInfo;

    if (this.props.model.company_vat_number) {
      vat = <div>BTW {this.props.model.company_vat_number.toUpperCase()} <Label title="?" link={'https://be.kompass.com/searchCompanies?text='+this.props.model.company_vat_number} modifiers={['dim', 'compact']}/></div>
    }

    if (this.props.model.company_vat_number || this.props.model.company_name) {
      companyInfo = (
        <div>
          <div>{this.props.model.company_name}</div>
          {vat}
        </div>
      );
    }

    return (
      <div className="list-item">
        <div className="list-item__column list-item__column--valign-top list-item__column--clean">
          <CustomerCard customer={this.props.model} showAddress={false} />
        </div>
        <div className="list-item__column list-item__column--valign-top">
          <Address
            street={this.props.model.address_street}
            number={this.props.model.address_street_number}
            postalCode={this.props.model.address_postal_code}
            city={this.props.model.address_city}
            country={this.props.model.address_country}
          />
        </div>
        <div className="list-item__column list-item__column--valign-top">
          {companyInfo}
        </div>
      </div>
    );
  }
}

export default CustomerListItem;