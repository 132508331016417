import React from 'react';

import Label from "../../atoms/label";

class Address extends React.Component {

  static defaultProps = {
    street: '',
    number: '',
    postalCode: '',
    city: '',
    country: ''
  };

  render() {

    let googleMapsUrl = 'https://www.google.com/maps/place/'+this.props.street+'+'+this.props.number+'+,'+this.props.postalCode+'+'+this.props.city;

    return (
      <div>
        <div>
          {this.props.street+' '+this.props.number}
        </div>
        <div>
          {this.props.postalCode+' '+this.props.city}<br />
          {this.props.country}&ensp;
          <Label title="map" modifiers={['dim', 'compact']} link={googleMapsUrl} />
        </div>
      </div>
    );
  }
}

export default Address;