import React from "react";

import ModuleView from "../../module-view/index";
import CustomerIndex from "../customer-index/index";
import CustomerCreate from "../customer-create";
import Popup from "../../popup";
import Grid from "../../grid";
import Window from "../../window";
import IconButton from "../../../atoms/icon-button";
import CustomerCard from "../customer-card";
import Search from "../../search";
import Pagination from "../../pagination";
import List from "../../list";
import OrderListItem from "../../order/order-list-item";
import CustomerEdit from "../customer-edit";
import OrderView from "../../order/order-view";

class CustomerModule extends ModuleView {

  constructor(props) {

    super(props);

    this.views = {
      index: <CustomerIndex onCreate={() => this.openPopup('createCustomer')} onView={customer => this.openPopup('viewCustomer', customer, customer.id)} />
    };

    this.popups = {
      createCustomer: () => {

        return (
          <Popup title="Create customer">
            <CustomerCreate onSave={customer => {

              this.closePopup('createCustomer');
              this.openPopup('viewCustomer', customer, customer.id);

            }} onCancel={() => this.closePopup('createCustomer')}/>
          </Popup>
        );
      },
      viewCustomer: (customer) => {

        return (
          <Popup title={customer.first_name+' '+customer.last_name} fullscreen={true} toggle={true}>
            <Grid cols="1fr 2fr">
              <Window header={<IconButton iconType="edit" onClick={e => this.openPopup('editCustomer', customer, customer.id)}/>} modifiers={['frame', 'padded']}>
                <CustomerCard customer={customer} />
              </Window>
              <Window header={<Search listId="customer-orders" />} footer={<Pagination listId="customer-orders" />} modifiers="frame">
                <List
                  id={'customer-orders'}
                  model={'orders'}
                  filters={{
                    filter_customer: [
                      { key: customer.id }
                    ]
                  }}
                  onItemClick={item => {
                    this.openPopup('viewCustomerOrder', item, item.id);
                  }}
                >
                  <OrderListItem />
                </List>
              </Window>
            </Grid>
          </Popup>
        );
      },
      editCustomer: (customer) => {

        return (
          <Popup title={'Edit '+customer.first_name}>
            <CustomerEdit customer={customer} onSave={customer => {

              this.closePopup('editCustomer', customer.id);
              this.openPopup('viewCustomer', customer, customer.id);

            }} />
          </Popup>
        );
      },
      viewCustomerOrder: (order) => {

        return (
          <Popup title={'Order '+order.id} toggle={true}>
            <OrderView order={order} />
          </Popup>
        );
      }
    };

    this.state = {
      view: this.views.index
    };
  }
}

export default CustomerModule;