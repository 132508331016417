import './index.scss';

import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../state/modules/resources";

import React from 'react';
import moment from 'moment';

import DateRangePicker from "../../atoms/date-range-picker";
import ActionInput from "../action-input";
import IconButton from "../../atoms/icon-button";

class DateRangeFilter extends React.Component
{
  static defaultProps = {
    listId: null,
    filterKey: '',
    hasFocus: false
  };

  constructor(props) {

    super(props);

    this.state = {
      startDate: '',
      endDate: '',
      hasFocus: this.props.hasFocus,
      hasValue: false
    };
  }

  search(dates) {

    let {start, end} = dates;

    if (! start.isValid() && ! end.isValid()) {

      this.setState({
        startDate: '',
        endDate: '',
        hasValue: false,
        hasFocus: false
      });

      return;
    }

    this.props.updateList(this.props.listId, {
      filters: {
        [this.props.filterKey]: [
          {key: 'gte:'+start.unix()},
          {key: 'lte:'+end.unix()},
        ],
      }
    });

    this.setState({
      startDate: start,
      endDate: end
    });

    this.blur();
  }

  clearDates() {

    this.props.updateList(this.props.listId, {
      filters: {
        [this.props.filterKey]: null,
      }
    });

    this.setState({
      startDate: '',
      endDate: '',
      hasValue: false,
      hasFocus: false
    });
  }

  focus() {

    this.setState({ hasFocus: true });
  }

  blur() {

    this.setState({
      hasFocus: false,
      hasValue: true
    });
  }

  convertDateRange(datesString) {

    let dates = datesString.split(' - ');

    return {start: moment(dates[0], 'DD-MM-YYYY'), end: moment(dates[1], 'DD-MM-YYYY')};
  }

  formatValue(date, format = 'DD-MM-YYYY') {
    return date ? date.format(format) : '...';
  }

  render() {

    if (! this.state.hasFocus && ! this.state.hasValue) {
      return (
        <IconButton iconType="calendar" modifiers="button" onClick={e => this.focus()} />
      );
    }

    return (
      <div className={'date-range-filter'}>

        <div className="date-range-filter__input">
          <ActionInput
            placeholder={'Select date'}
            onFocus={e => this.focus()}
            onSubmit={dates => {
              if (this.state.hasFocus) {
                this.search(this.convertDateRange(dates));
                return;
              }

              this.focus();
            }}
            default={this.formatValue(this.state.startDate)+' - '+ this.formatValue(this.state.endDate)}
            buttonIcon={'calendar'}
          />
        </div>

        {this.state.hasFocus && (
          <div className="date-range-filter__picker">
            <DateRangePicker
              onDatesChanged={dates => this.search(dates)}
              onClear={() => this.clearDates()}
              value={(this.state.startDate && this.state.endDate) ? moment.range(this.state.startDate, this.state.endDate) : null}
            />
          </div>
        )}

      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeFilter);