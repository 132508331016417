export default function modifiers(componentName, modifiers) {

    if (!modifiers) {
        return '';
    }

    let m = '';

    if (Array.isArray(modifiers)) {
        modifiers.forEach(modifier => m += ' ' + componentName + '--' + modifier);
        return m;
    }

    return ' ' + componentName + '--' + modifiers;
}