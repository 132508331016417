import React from "react";

import List from "../list";
import Popup from "../popup";
import Search from "../search";
import ButtonGroup from "../button-group";
import Button from "../../atoms/button";
import PaginationArrows from "../pagination-arrows";

class ItemSelectPopup extends React.Component {

  static defaultProps = {
    listId: null,
    model: null,
    filters: null,
    search: null,
    itemName: 'item',
    selectedItem: null,
    fullscreen: true,
    required: false,
    onItemSelect: () => {},
    onItemDeselect: () => {},
    onItemSelectConfirm: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      selectedItem: this.props.selectedItem || null
    };
  }

  getFooter() {

    let footer = [
      <PaginationArrows key={'pagination-arrows'} listId={this.props.listId} model={this.props.model} />
    ];

    if (this.state.selectedItem) {

      footer.push(
        <ButtonGroup key={'selection-button'}>
          {! this.props.required && <Button modifiers="alt" text={'Deselect'+(this.props.itemName ? ' '+this.props.itemName : '')} onClick={e => this.props.onItemDeselect()}/>}
          <Button text={'Select'+(this.props.itemName ? ' '+this.props.itemName : '')} onClick={e => this.props.onItemSelectConfirm(this.state.selectedItem)} />
        </ButtonGroup>
      );
    }

    return footer;
  }

  getHeader() {

    return <Search key={'search'} placeholder={(this.props.itemName ? 'Search '+this.props.itemName : null)} listId={this.props.listId} />;
  }

  render() {
    return (
      <Popup {...this.props} title={'Select'+(this.props.itemName ? ' '+this.props.itemName : '')} header={this.getHeader()} footer={this.getFooter()} padded={false}>
        <List
          id={this.props.listId}
          model={this.props.model}
          limit={8}
          search={this.props.search}
          filters={this.props.filters}
          activeItemId={this.state.selectedItem ? this.state.selectedItem.id : null}
          submittable={true}
          onSubmit={item => this.props.onItemSelectConfirm(item)}
          onItemClick={item => {
            this.setState({
              selectedItem: item
            }, () => this.props.onItemSelect(item));
          }}
          onItemDoubleClick={item => {
            this.setState({
              selectedItem: item
            }, () => this.props.onItemSelect(item));
  
            this.props.onItemSelectConfirm(item)
          }}
        >
          {this.props.children}
        </List>
      </Popup>
    );
  }
}

export default ItemSelectPopup;