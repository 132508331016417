import './index.scss';

import {ResizableBox} from 'react-resizable';

import React from 'react';
import modifiers from "../../../util/components";
import window from "../../../util/window";

import Draggable from 'react-draggable';

class Window extends React.Component {
    
    static defaultProps = {
        title: '',
        header: null,
        footer: null,
        children: [],
        fullscreen: false,
        defaultPosition: {x: 0, y: 0},
        onDragStart: () => {
        },
        onDragStop: () => {
        }
    };
    
    constructor(props) {
        
        super(props);
        
        this.state = {
            isDragging: false,
            width: this.props.draggable ? (this.props.fullscreen ? window.getWidth(85) : window.getWidth(40)) : 'auto',
            height: this.props.draggable ? (this.props.fullscreen ? window.getHeight(90) : window.getHeight(65)) : 'auto'
        };
    }
    
    handleDragStart(e) {
        
        this.setState({isDragging: true});
        this.props.onDragStart(e);
    }
    
    handleDragStop(e, data) {
        
        this.setState({isDragging: false});
        this.props.onDragStop(e, data);
    }
    
    handleResize = (event, {element, size, handle}) => {
        
        this.setState({
            width: size.width,
            height: size.height
        });
    };
    
    handleHeaderDoubleClick(e) {
        
        if (this.props.draggable) {
            this.setState({
                width: window.getWidth(85),
                height: window.getHeight(90)
            });
        }
    }
    
    render() {
        
        let header;
        let footer;
        let dragHandle;
        
        if (this.props.draggable) {
            dragHandle = (
                <div className="window__drag">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                        <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M14.75 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM8.75 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"/>
                    </svg>
                </div>
            );
        }
        
        if (this.props.title || this.props.header) {
            header = (
                <div className="window__header" onDoubleClick={this.handleHeaderDoubleClick.bind(this)}>
                    <div className="window__title">
                        {dragHandle}
                        {this.props.title}
                    </div>
                    <div className="window__actions">
                        {this.props.header}
                    </div>
                </div>
            );
        }
        
        if (this.props.footer) {
            footer = (
                <div className="window__footer">
                    {this.props.footer}
                </div>
            );
        }
        
        let windowStyle = {
            width: (this.state.width === 'auto' ? this.state.width : this.state.width + 'px'),
            height: (this.state.height === 'auto' ? this.state.height : this.state.height + 'px')
        };
        
        let window = (
            <div
                className={'window' + modifiers('window', this.props.modifiers) + (this.props.draggable ? ' window--draggable' : '') + (this.props.fullscreen ? ' window--fullscreen' : '') + (this.state.isDragging ? ' window--dragging' : '')}
                style={windowStyle}
            >
                {header}
                <div className="window__main">
                    {this.props.children}
                </div>
                {footer}
            </div>
        );
        
        if (this.props.draggable) {
            return (
                <Draggable
                    handle=".window__drag"
                    defaultPosition={this.props.defaultPosition}
                    onStart={this.handleDragStart.bind(this)}
                    onStop={this.handleDragStop.bind(this)}
                >
                    <ResizableBox
                        width={this.state.width}
                        height={this.state.height}
                        minConstraints={[300, 200]}
                        handle={<div className="window__drag-handle"></div>}
                        onResize={this.handleResize.bind(this)}
                    >
                        {window}
                    </ResizableBox>
                </Draggable>
            );
        }
        
        return window;
    }
}

export default Window;