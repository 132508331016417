import React from 'react';
import { photoSrc } from "../../../../util/media";
import { price } from "../../../../util/formatting";

import IconButton from "../../../atoms/icon-button";
import ButtonGroup from "../../button-group";

class InventoryListItem extends React.Component {

  static defaultProps = {
    model: {},
    onEdit: () => {},
    onDelete: () => {}
  };

  render() {

    let title = this.props.model.title;
    let artist = this.props.model.artist ? this.props.model.artist.name : '';
    let label = this.props.model.label;
    let barcode = this.props.model.barcode;
    let thumbnail_url = photoSrc(this.props.model.thumbnail_url);
    let stock = this.props.model.stock && this.props.model.stock || 0;
    let stock_2 = this.props.model.stock_2 && this.props.model.stock_2 || 0;

    if (! this.props.model.id) {
      artist = this.props.model.artist;
      label = (this.props.model.labels && this.props.model.labels.length) ? this.props.model.labels[0].name : '';
      thumbnail_url = this.props.model.cover;
    }

    return (
      <div className="list-item">
        <div className="list-item__thumb">
          <img src={thumbnail_url} alt={title} />
        </div>
        <div className="list-item__column list-item__column--clean list-item__column--span-5">
          {title}<br />
          {artist}<br />
          {label}<br />
          {this.props.model.price && price(this.props.model.price)}
        </div>
        <div className="list-item__column list-item__column--span-2">
          Barcode: {barcode}<br />
          Offline stock: {stock_2} {this.props.model.amount && this.props.model.amount.stock_2 && '('+this.props.model.amount.stock_2+')'}<br />
          Online stock: {stock} {this.props.model.amount && this.props.model.amount.stock && '('+this.props.model.amount.stock+')'}<br />
        </div>
        <div className="list-item__column list-item__column--clean">
          <ButtonGroup>
            <IconButton onClick={e => this.props.onEdit(this.props.model)} iconType={'edit'} />
            <IconButton onClick={e => this.props.onDelete(this.props.model)} iconType={'delete'} />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default InventoryListItem;