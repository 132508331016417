import './index.scss';

import React from 'react';

import IconLabel from "../../atoms/icon-label";
import Address from "../address";

class ProfileCard extends React.Component {

  static defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    street: '',
    number: '',
    postalCode: '',
    city: '',
    country: '',
    showAddress: true
  };

  render() {

    let address;

    if (this.props.showAddress) {
      address = (
        <div className="profile-card__address">
          <Address
            street={this.props.street}
            number={this.props.number}
            postalCode={this.props.postalCode}
            city={this.props.city}
            country={this.props.country}
          />
        </div>
      );
    }

    return (

      <div className="profile-card">
        <div className="profile-card__avatar">
          {(this.props.firstName[0]+this.props.lastName[0])}
        </div>
        <div className="profile-card__main">
          <div className="profile-card__header">
            <span className="profile-card__title">
              {this.props.firstName+' '+this.props.lastName}
            </span>
          </div>
          <div className="profile-card__content">
            <IconLabel text={this.props.email} type="mail" iconModifiers={['small', 'highlight']} /><br />
            <IconLabel text={this.props.telephone} type="phone" iconModifiers={['small', 'highlight']} />
          </div>
          {address}
        </div>
      </div>
    );
  }
}

export default ProfileCard;