import "./index.scss";

import { connect } from "react-redux";
import { authenticate } from "../../../state/modules/auth";

import React from "react";

import LoginForm from "../../molecules/login-form";
import Logo from "../../atoms/logo";

class Login extends React.Component {

  handleLogin(data) {

    this.props.authenticate(data.email, data.password);
  }

  render() {

    let loginPhoto = '/'+process.env.PUBLIC_URL + 'img/login.jpg';

    return (
      <div className="login">
        <div className="login__column">

          <div className="login__photo" style={{backgroundImage: "url('"+loginPhoto+"')"}}>
            Login photo
          </div>
        </div>

        <div className="login__column">

          <div className="login__main">

            <div className="login__header">
              <div className="login__logo">
                <Logo/>
              </div>
            </div>

            <div className="login__content">
              <div className="login__form">
                <LoginForm onSubmit={data => this.handleLogin(data)} />
              </div>
            </div>

            <div className="login__footer">&nbsp;</div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ isAuthenticated: state.auth.isAuthenticated });
const mapDispatchToProps = { authenticate };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
