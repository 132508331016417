import './index.scss';

import React from 'react';

class EnumSwitch extends React.Component
{
  static defaultProps = {
    values: [],
    activeKey: null,
    onItemSelect: () => {}
  };

  getValues() {

    return this.props.values.map(value => {
      return (
        <div
          key={value.key}
          className={'enum-switch__item'+(value.key === this.props.activeKey ? ' enum-switch__item--active' : '')}
          onClick={e => this.props.onItemSelect(value.key)}
        >
          {value.label}
        </div>
      );
    });
  }

  render() {

    return (
      <div className="enum-switch">
        {this.getValues()}
      </div>
    );
  }
}

export default EnumSwitch;