/*
  Constants
 */
export const START_LOADING = "utils/START_LOADING";
export const STOP_LOADING = "utils/STOP_LOADING";

/*
  Action Creators
 */
export function startLoading() {
  return {
    type: START_LOADING,
    payload: {
      isLoading: true
    }
  }
}

export function stopLoading() {
  return {
    type: STOP_LOADING,
    payload: {
      isLoading: false
    }
  }
}

/*
  Initial State
 */
const initialState = {
  isLoading: false
};

/*
  Reducer
 */
export default function reducer(state = initialState, action) {

  switch (action.type) {

    case START_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }

    case STOP_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }

    default:
      return state;
  }
}