import { modules } from "../../util/modules";

/*
  Constants
 */
export const SET_ACTIVE_MODULE = 'modules/SET_ACTIVE_MODULE';
export const SET_MODULES = 'modules/SET_MODULES';

/*
  Actions Creators
 */
export function setActiveModule(module) {
  return {
    type: SET_ACTIVE_MODULE,
    payload: {
      module: module
    }
  };
}

export function setModules(modules) {
  return {
    type: SET_MODULES,
    payload: {
      modules: modules
    }
  }
}

/*
  Initial State
 */
const initialState = {
  modules: modules,
  activeModule: Object.keys(modules)[0]
};

/*
  Reducer
 */
export default function reducer(state = initialState, action) {

  switch(action.type) {

    case SET_ACTIVE_MODULE: {
      return {
        ...state,
        activeModule: Object.keys(modules).find(m => m === action.payload.module)
      }
    }

    case SET_MODULES: {
      return {
        ...state,
        modules: action.payload.modules
      }
    }

    default:
      return state;

  }
}