import './index.scss';

import { updateList } from "../../../state/modules/resources";

import React from 'react';

import Icon from "../../atoms/icon";
import connect from "react-redux/es/connect/connect";

class FilterList extends React.Component {

  static defaultProps = {
    listId: null,
    values: {},
    onChange: () => {}
  };

  handleChange(filterKey, key) {

    let values = this.props.values[filterKey];
    values = values.filter(value => value.key !== key);

    this.props.updateList(this.props.listId, {
      filters: {
        [filterKey]: values
      }
    });

    this.props.onChange(filterKey, values);
  }

  render() {

    // @TODO continue this
    // console.log( this.props.list ? (this.props.list.filters || null ) : null );

    let hasItems = false;

    let renderedItems = Object.entries(this.props.values).map(([filterKey, items]) => {

      if (items && items.length) {

        hasItems = true;

        return items.map((item, index) => {
          return (
            <div key={index} className="filter-list__item" onClick={e => this.handleChange(filterKey, item.key)}>
              {item.value}
              <span className="filter-list__action">
                <Icon type={'close'} />
              </span>
            </div>
          );
        });

      }
    });

    if (!hasItems) {
      return <div></div>;
    }

    return (
      <div className="filter-list">
        {renderedItems}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  list: state.resources.lists[ownProps.listId]
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);