import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import DataValue from "../../data-value";
import {dateTime, price} from "../../../../util/formatting";
import Grid from "../../grid";
import Window from "../../window";
import ButtonGroup from "../../button-group";
import Button from "../../../atoms/button";
import RegisterTransactionItems from "../register-transaction-items";
import {validateForm} from "../../../../util/validation";
import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";
import StatusLabel from "../../../atoms/status-label";
import ui from "../../../../util/ui";
import HiddenInput from "../../../atoms/hidden-input";

class RegisterEdit extends React.Component
{
  static defaultProps = {
    register: {},
    onSave: () => {},
    onRegisterTransactionCreate: () => {}
  };

  constructor(props) {

    super(props);
    this.state = {
      register: this.props.register,
      cash_counted: this.props.register.expected_cash_amount,
      card_counted: this.props.register.card_amount,
      mollie_counted: this.props.register.mollie_counted,
      discount_counted: this.props.register.discount_amount,
    };

    this.registerEditForm = React.createRef();
  }

  componentDidUpdate(prevProps) {

    if (this.props.register && (prevProps.register !== this.props.register)) {
      this.fetchRegister();
    }
  }

  componentDidMount() {

    this.fetchRegister();
  }

  fetchRegister() {

    http.request({
      url: API_HOST+API_ROOT+'registers/'+this.props.register.id
    }, true ).then(response => {
      console.log(response.result)
      this.setState({
        register: response.result,
        cash_counted: response.result.expected_cash_amount,
        card_counted: response.result.card_amount,
        mollie_counted: response.result.mollie_counted,
        discount_counted: response.result.discount_amount,
      });
    });
  }

  validateForm(form, data) {

    validateForm(form, data, {
      cash_counted: ['required', 'number'],
      discount_counted: ['required', 'number'],
      card_counted: ['required', 'number'],
      mollie_counted: ['required', 'number'],
    });
  }

  handleChange(fieldName, value) {
    this.setState({[fieldName]: value});
  }

  onSubmit(registerData) {

    http.patch(API_HOST+API_ROOT+'registers/'+this.state.register.id, registerData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      ui.notify('There was an error, register couldn\'t be closed');
    });
  }

  render() {

    let register = this.state.register;

    if (! register) {
      return;
    }
    console.log(this.state.mollie_counted)
    console.log(register.mollie_amount)

    return (
        <Grid cols={'5fr 3fr'}>
          <Grid rows={'1fr auto'}>
            <Window title={'Payment Tally'} modifiers={'frame'}>
              <div className="list">
                <div className="list__items">
                <span className="header">
                  <span className="header-column">Payment type</span>
                  <span className="header-column">Expected</span>
                  <span className="header-column">Counted</span>
                  <span className="header-column">Difference</span>
                </span>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Cash</span>
                      <span className="list-item__column">{price(register.expected_cash_amount)}</span>
                      <span className="list-item__column">{price(this.state.cash_counted)}</span>
                      <span className="list-item__column">
                        {(this.state.cash_counted-register.expected_cash_amount < 0 ?
                                <StatusLabel modifiers={'error'} title={price(this.state.cash_counted-register.expected_cash_amount)} /> :
                                price(this.state.cash_counted-register.expected_cash_amount)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Card</span>
                      <span className="list-item__column">{price(register.card_amount)}</span>
                      <span className="list-item__column">{price(this.state.card_counted)}</span>
                      <span className="list-item__column">
                        {(this.state.card_counted-register.card_amount < 0 ?
                                <StatusLabel modifiers={'error'} title={price(this.state.card_counted-register.card_amount)} /> :
                                price(this.state.card_counted-register.card_amount)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Mollie</span>
                      <span className="list-item__column">{price(register.mollie_amount)}</span>
                      <span className="list-item__column">{price(this.state.mollie_counted)}</span>
                      <span className="list-item__column">
                        {(this.state.mollie_counted-register.mollie_amount < 0 ?
                                <StatusLabel modifiers={'error'} title={price(this.state.mollie_counted-register.mollie_amount)} /> :
                                price(this.state.mollie_counted-register.mollie_amount)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Discount</span>
                      <span className="list-item__column">{price(register.discount_amount)}</span>
                      <span className="list-item__column">{price(this.state.discount_counted)}</span>
                      <span className="list-item__column">
                        {(this.state.discount_counted-register.discount_amount < 0 ?
                                <StatusLabel modifiers={'error'} title={price(this.state.discount_counted-register.discount_amount)} /> :
                                price(this.state.discount_counted-register.discount_amount)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Window>
            <Window>
              <Form ref={this.registerEditForm}
                    validate={(form, data) => this.validateForm(form, data)}
                    onSubmit={(data) => this.onSubmit(data)}
                    submitButtonText={'Close register'}
              >
                <HiddenInput name="closed" value={new Date().getTime() / 1000} />
                <InputStack>
                  <TextInput name="cash_counted" label="Cash counted" showRequiredIndicator={true} default={this.state.cash_counted} onChange={value => this.handleChange('cash_counted', value)}/>
                  <TextInput name="card_counted" label="Card counted" showRequiredIndicator={true} default={this.state.card_counted} onChange={value => this.handleChange('card_counted', value)}/>
                  <TextInput name="mollie_counted" label="Mollie counted" showRequiredIndicator={true} default={this.state.mollie_counted} onChange={value => this.handleChange('mollie_counted', value)}/>
                  <TextInput name="discount_counted" label="Discount counted" showRequiredIndicator={true} default={this.state.discount_counted} onChange={value => this.handleChange('discount_counted', value)}/>
                </InputStack>
                <TextInput name={'note'} label={'Notes'} default={register.note} />
              </Form>
            </Window>
          </Grid>
          <Grid rows={'1fr auto'}>
            <Window title={'Summary'}>
              <DataValue title="Created">
                {dateTime(register.created)}
              </DataValue>
              <DataValue title="Total sales">
                {price(register.total_sales)}
              </DataValue>
            </Window>
            <Window title={'Cash In/Out'} header={
              <ButtonGroup>
                <Button text="In" onClick={e => this.props.onRegisterTransactionCreate(this.state.register, 'in')}/>
                <Button text="Out" onClick={e => this.props.onRegisterTransactionCreate(this.state.register, 'out')} />
              </ButtonGroup>
            }>
              <RegisterTransactionItems items={register.register_transactions}/>
            </Window>
          </Grid>
        </Grid>
    );
  }
}

export default RegisterEdit;