import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class HeaderColumn extends React.Component {

  static defaultProps = {
    title: '',
    field: '',
    sorting: null,
    defaultSorting: null,
    modifiers: null,
    onChange: () => {}
  };

  sort(e) {

    if (this.props.field) {
      this.props.onChange(this.props.field, this.getReverseSortMethod(), e.shiftKey);
    }
  }

  getReverseSortMethod() {

    let sortMethod;

    if (! this.props.sorting) {
      sortMethod = (this.props.defaultSorting ? (this.props.defaultSorting === 'asc' ? 'desc' : 'asc' ) : 'asc');
    } else {
      sortMethod = this.props.sorting === 'desc' ? 'asc' : 'desc';
    }

    return sortMethod;
  }

  getSortMethod() {

    let sortMethod;

    if (! this.props.sorting) {
      sortMethod = (this.props.defaultSorting ? this.props.defaultSorting : 'asc');
    } else {
      sortMethod = this.props.sorting;
    }

    return sortMethod;
  }

  render() {

    let sortableModifier = (this.props.sorting || this.props.defaultSorting ? ' header-column--sortable' : '');
    let sortMethodModifier = ' header-column--'+this.getSortMethod();

    return (
      <span onClick={e => this.sort(e)} className={'header-column'+sortableModifier+sortMethodModifier+modifiers('header-column', this.props.modifiers)}>
        {this.props.title}
      </span>
    );
  }
}

export default HeaderColumn;