import React from 'react';
import {date, price} from "../../../../util/formatting";

class ClientOrderListItem extends React.Component
{
  static defaultProps = {
    item: {}
  };

  render() {

    if (! this.props.item) {
      return;
    }

    return (
      <div className="list-item">
        <div className="list-item__column">
          {this.props.model.title}<br />
          {this.props.model.note}
        </div>
        <div className="list-item__column">
          {date(this.props.model.created)}
        </div>
        <div className="list-item__column">
          {this.props.model.email}
        </div>
        <div className="list-item__column">
          Price: {price(this.props.model.total_price)}<br />
          Advance: {price(this.props.model.advance)}<br />
          Remaining: {price(this.props.model.remaining_amount)}
        </div>
      </div>
    );
  }
}

export default ClientOrderListItem;