import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class Grid extends React.Component {

  static defaultProps = {
    cols: '',
    rows: '',
    modifiers: null
  };

  render() {

    let style = {
      gridTemplateColumns: this.props.cols,
      gridTemplateRows: this.props.rows
    };

    return (
      <div className={'grid'+modifiers('grid', this.props.modifiers)} style={style}>
        {this.props.children}
      </div>
    );
  }
}

export default Grid;