import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import DataValue from "../../data-value";
import {dateTime, price} from "../../../../util/formatting";
import Grid from "../../grid";
import Window from "../../window";
import RegisterTransactionItems from "../register-transaction-items";
import Search from "../../search";
import Pagination from "../../pagination";
import List from "../../list";
import StoreOrderListItem from "../../order/store-order-list-item";

class RegisterView extends React.Component
{
  static defaultProps = {
    register: {},
    onOrderClick: () => {}
  };

  constructor(props) {

    super(props);
    this.state = {register: this.props.register};
  }

  componentDidUpdate(prevProps) {

    if (this.props.register && (prevProps.register !== this.props.register)) {
      this.fetchRegister();
    }
  }

  componentDidMount() {

    this.fetchRegister();
  }

  fetchRegister() {

    http.request({
      url: API_HOST+API_ROOT+'registers/'+this.props.register.id
    }, true ).then(response => {
      this.setState({register: response.result});
    });
  }

  render() {

    let register = this.state.register;
    let note;

    if (register.note) {
      note = (
        <Window>
          <p>{register.note}</p>
        </Window>
      );
    }

    return (
        <Grid cols={'2fr 1fr'}>
          <Grid rows={'1fr'+note ? 'auto' : ''+'2fr'}>
            <Window title={'Payment Tally'} modifiers={'frame'}>
              <div className="list">
                <div className="list__items">
                <span className="header">
                  <span className="header-column">Payment type</span>
                  <span className="header-column">Expected</span>
                  <span className="header-column">Counted</span>
                  <span className="header-column">Difference</span>
                </span>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Cash</span>
                      <span className="list-item__column">{price(register.expected_cash_amount)}</span>
                      <span className="list-item__column">{price(register.cash_counted)}</span>
                      <span className="list-item__column">{price(register.cash_counted-register.expected_cash_amount)}</span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Card</span>
                      <span className="list-item__column">{price(register.card_amount)}</span>
                      <span className="list-item__column">{price(register.card_counted)}</span>
                      <span className="list-item__column">{price(register.card_counted-register.card_amount)}</span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Mollie</span>
                      <span className="list-item__column">{price(register.mollie_amount)}</span>
                      <span className="list-item__column">{price(register.mollie_counted)}</span>
                      <span className="list-item__column">{price(register.mollie_counted-register.mollie_amount)}</span>
                    </div>
                  </div>
                  <div className="list__item">
                    <div className="list-item">
                      <span className="list-item__column">Discount</span>
                      <span className="list-item__column">{price(register.discount_amount)}</span>
                      <span className="list-item__column">{price(register.discount_counted)}</span>
                      <span className="list-item__column">{price(register.discount_counted-register.discount_amount)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Window>
            {note}
            <Window title={'Orders'} header={<Search listId="register-orders" />} footer={<Pagination listId="register-orders" />} modifiers="frame">
              <List
                id={'register-orders'}
                model={'orders'}
                filters={{
                  filter_register: [
                    { key: register.id }
                  ]
                }}
                onItemClick={item => this.props.onOrderClick(item)}
              >
                <StoreOrderListItem />
              </List>
            </Window>
          </Grid>
          <Grid rows={'min-content'}>
            <Window title={'Summary'}>
              <DataValue title="Created">
                {dateTime(register.created)}
              </DataValue>
              <DataValue title="Closed">
                {register.closed ? dateTime(register.closed) : '-'}
              </DataValue>
              <DataValue title="Total sales">
                {price(register.total_sales)}
              </DataValue>
            </Window>
            <Window title={'Cash In/Out'}>
              <RegisterTransactionItems items={register.register_transactions}/>
            </Window>
          </Grid>
        </Grid>
    );
  }
}

export default RegisterView;