import React from 'react';
import {photoSrc} from "../../../../util/media";
import {price} from "../../../../util/formatting";

import Label from "../../../atoms/label";

class OrderGiftcardItems extends React.Component
{
  static defaultProps = {
    items: [],
  };

  render() {

    if (! this.props.items) {
      return <div></div>;
    }

    return (
      <div className='list'>
        <div className="list__items">
          {this.props.items.map((item, key) => {
            return (
              <div key={key} className="list__item">
                <div className="list-item">
                  <div className="list-item__thumb">
                    <img src={photoSrc(item.thumbnail_url)} alt={item.title} />
                  </div>
                  <div className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-6">
                    {item.title}<br />
                    {item.subtitle && (<span>{item.subtitle}<br /></span>)}
                    {price(item.price)}
                  </div>
                  <div className="list-item list-item__column--clean list-item__column--valign-top">
                    <span>
                      <Label title="Print barcode" link={process.env.REACT_APP_WEB_HOST+'/stock/giftcards/barcode/'+item.identifier+'/'}/>
                    </span><br />
                    <span>
                      <Label title="Print message" link={process.env.REACT_APP_WEB_HOST+'/stock/giftcards/message/'+item.identifier+'/'}/>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default OrderGiftcardItems;