import localStorage from './local-storage';

import urlUtils from '../util/url';

export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const http = (() => {

  function evaluateResponse (response) {

    let json = response.json();

    return json.then(response => {

      if (response.success) {
        return json;
      }

      throw new Error(response.error_code);
    });
  }

  return {

    request(config = {}, authRequired = false) {
      if (authRequired) {
        config.headers = {
          'Authorization': 'Bearer '+localStorage.get('ACCESS_TOKEN')
        };
      }

      return fetch(config.url, config).then(evaluateResponse);
    },

    get(url, authRequired = false, params = {}) {
      return this.request({
        url: urlUtils.urlWithQuery(url, params)
      }, authRequired);
    },

    post(url, data, authRequired = false) {

      let formData = new FormData();

      for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
          formData.append(prop, JSON.stringify(data[prop]));
        }
      }

      return this.request({
        url: url,
        method: 'POST',
        body: formData
      }, authRequired);
    },

    put(url, data, authRequired = false) {
      return this.request({
        url: url,
        method: 'PUT',
        body: JSON.stringify(data)
      }, authRequired);
    },

    patch(url, data, authRequired = false) {
      return this.request({
        url: url,
        method: 'PATCH',
        body: JSON.stringify(data)
      }, authRequired);
    },

    delete(url, authRequired = false) {
      return this.request({
        url: url,
        method: 'DELETE',
      }, authRequired);
    }
  }
})();