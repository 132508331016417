import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

import iconList from "../../../util/icon-list";

export default class IconButton extends React.Component {

  static defaultProps = {
    iconType: 'add',
    type: 'button',
    onClick: () => {}
  };

  handleClick(e) {

    e.stopPropagation();
    this.props.onClick(e);
  }

  render() {

    return (
      <button className={'icon-button'+modifiers('icon-button', this.props.modifiers)} type={this.props.type} onClick={this.handleClick.bind(this)}>
        {iconList[this.props.iconType]}
      </button>
    );
  }
}