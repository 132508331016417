import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";
import clsx from "clsx";
import iconList from "../../../util/icon-list";
import loginForm from "../../molecules/login-form";

export default class TagInput extends React.Component {

    static defaultProps = {
        autocomplete: false,
        suggestions: [],
        default: '',
        label: '',
        name: '',
        modifiers: null,
        disabled: false,
        placeholder: '',
        showRequiredIndicator: false,
        focus: false,
        type: 'text',
        onChange: () => {},
        onFocus: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            id: 'input-' + this.props.name + '-' + Math.random().toString(36).substring(7),
            value: props.default,
            activeSuggestion: 0,
            selectedSuggestions: [],
            filteredSuggestions: props.suggestions,
            showSuggestions: false,
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.addSelected = this.addSelected.bind(this);
        this.removeSelected = this.removeSelected.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedSuggestions: this.props.suggestions.filter(suggestion => {
                return suggestion.selected === true;
            }),
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.default !== prevProps.default) {
            this.setState({
                value: this.props.default,
            });
        }

        if (this.props.suggestions !== prevProps.suggestions) {
            this.setState({
                selectedSuggestions: this.props.suggestions.filter(suggestion => {
                    return suggestion.selected === true;
                }),
            });
        }
    }

    getValue() {
        return this.state.value;
    }

    updateValue(value = '') {
        this.setState({
            value: value
        });
    }

    addSelected(suggestion) {
        let {filteredSuggestions, selectedSuggestions} = this.state;

        if (! suggestion || selectedSuggestions.includes(suggestion)) {
            return;
        }

        selectedSuggestions.push(suggestion);

        this.updateValue('');

        this.setState({
            activeSuggestion: 0,
            selectedSuggestions: selectedSuggestions,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: false,
        });
    }

    removeSelected(suggestion) {
        let {selectedSuggestions} = this.state;

        if (! suggestion || ! selectedSuggestions.includes(suggestion)) {
            return;
        }

        let index = selectedSuggestions.findIndex(selectedSuggestion => {
            return suggestion.value === selectedSuggestion.value;
        });

        selectedSuggestions.splice(index, 1);

        this.setState({
            activeSuggestion: 0,
            selectedSuggestions: selectedSuggestions,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: false,
        });
    }

    handleFocus(event) {
        let {selectedSuggestions, filteredSuggestions, showSuggestions} = this.state;

        if (showSuggestions) {
            return;
        }

        this.setState({
            activeSuggestion: this.getUnselectedSuggestionByIndex(),
            filteredSuggestions: this.props.suggestions,
            showSuggestions: true,
        });

        document.body.addEventListener('click', this.handleClickAway);

        setTimeout(() => {
            if (this.getUnselectedSuggestionByIndex() > 2) {
                this.getSuggestionElementByIndex(this.getUnselectedSuggestionByIndex()).scrollIntoView();
            }
        }, 100);
    }

    handleChange(e) {
        let {selectedSuggestions} = this.state;

        let value = e.target.value;

        let filteredSuggestions = this.props.suggestions.filter(suggestion => {
            return suggestion.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });

        let activeFilteredSuggestions = filteredSuggestions.filter(suggestion => {
            return ! selectedSuggestions.includes(suggestion.value);
        });

        this.setState({
            value: value,
            activeSuggestion: activeFilteredSuggestions.indexOf(activeFilteredSuggestions[0]),
            filteredSuggestions: filteredSuggestions,
            showSuggestions: this.props.autocomplete,
        });

        this.props.onChange(this.state.value);
    }

    handleSubmit(data) {
        let selected = this.state.selectedSuggestions.map(suggestion => {
            return suggestion.value;
        });

        data[this.props.name] = JSON.stringify(selected);
    }

    handleSuggestionClick(event) {
        let suggestion = this.props.suggestions.filter(suggestion => {
            return suggestion.value === event.target.getAttribute('value');
        })[0];

        this.addSelected(suggestion);
    }

    handleKeydown(event) {
        let {activeSuggestion, filteredSuggestions, selectedSuggestions, showSuggestions} = this.state;

        // Enter/Tab
        if (showSuggestions && (event.keyCode === 13 || event.keyCode === 9)) {
            this.addSelected(filteredSuggestions[activeSuggestion])

            this.setState({
                activeSuggestion: this.getUnselectedSuggestionByIndex(),
                showSuggestions: false,
            });

            event.preventDefault();
        }

        // Backspace
        if (event.keyCode === 8) {
            if (this.getInputElement().value) {
                return;
            }

            this.removeSelected(selectedSuggestions[selectedSuggestions.length - 1]);

            return;
        }

        // Escape
        if (event.keyCode === 27) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                filteredSuggestions: this.props.suggestions,
            });

            event.preventDefault();
        }

        // Arrow up
        if (event.keyCode === 38) {
            if (activeSuggestion - 1 < 0) {
                return;
            }

            this.getSuggestionElementByIndex(activeSuggestion - 1).scrollIntoView();

            this.setState({
                activeSuggestion: activeSuggestion - 1
            });

            event.preventDefault();
        }

        // Arrow down
        if (event.keyCode === 40) {
            if (! showSuggestions) {
                this.handleFocus();

                return;
            }

            if (activeSuggestion + 1 > filteredSuggestions.length - 1) {
                return;
            }

            this.getSuggestionElementByIndex(activeSuggestion + 1).scrollIntoView();

            this.setState({
                activeSuggestion: activeSuggestion + 1
            });

            event.preventDefault();
        }
    }

    handleClickAway(event) {
        let {showSuggestions} = this.state;

        if (! showSuggestions) {
            return;
        }

        if (event.target.closest('.tag-input')) {
            return;
        }

        this.setState({
            activeSuggestion: this.getUnselectedSuggestionByIndex(),
            filteredSuggestions: this.props.suggestions,
            showSuggestions: false,
        });

        document.body.removeEventListener('click', this.handleClickAway);
    }

    getUnselectedSuggestionByIndex(index = 0) {
        let {selectedSuggestions} = this.state;

        let unselectedSuggestions = this.props.suggestions.filter(suggestion => {
            return ! selectedSuggestions.includes(suggestion);
        });

        return this.props.suggestions.indexOf(unselectedSuggestions[index]);
    }

    getSuggestionElementByIndex(index = 0) {
        return document.querySelectorAll('.tag-input__suggestion')[index];
    }

    getInputElement() {
        return document.getElementById(this.state.id);
    }

    render() {
        let label;
        let suggestions;
        let selected;
        let requiredIndicator;

        let {activeSuggestion, filteredSuggestions, selectedSuggestions, showSuggestions} = this.state;

        if (this.props.showRequiredIndicator) {
            requiredIndicator = <span className="text-input__required-indicator">*</span>;
        }

        if (this.props.label) {
            label = (
                <label htmlFor={this.state.id} className="text-input__label">
                    {this.props.label}
                    {requiredIndicator}
                </label>
            );
        }

        suggestions = (
            <ul
                className={clsx({
                    'tag-input__suggestions': true,
                    'tag-input__suggestions--open': showSuggestions && this.props.suggestions.length,
                })}
            >
                {filteredSuggestions.length > 0 && filteredSuggestions.map((suggestion, index) => {
                    return (
                        <li
                            key={index}
                            value={suggestion.value}
                            onClick={this.handleSuggestionClick}
                            className={clsx({
                                'tag-input__suggestion': true,
                                active: activeSuggestion === index,
                                disabled: selectedSuggestions.includes(suggestion),
                            })}
                        >
                            {suggestion.label}
                        </li>
                    );
                })}
                {filteredSuggestions.length < 1 && (
                    <em>Nothing found.</em>
                )}
            </ul>
        );

        if (selectedSuggestions.length) {
            selected = selectedSuggestions.map((suggestion, index) => {
                return (
                    <span className="tag-input__tag" key={suggestion.value}>
                        {suggestion.label}

                        <span className="tag-input__tag--close" onClick={() => {this.removeSelected(suggestion)}}>
                            {iconList['close']}
                        </span>
                    </span>
                );
            });
        }

        return (
            <div className={"tag-input" + modifiers('tag-input', this.props.modifiers)}>
                {label}
                <div className="tag-input__wrap">
                    {selected}
                    <input id={this.state.id}
                           name={this.props.name}
                           className="tag-input__input"
                           type={this.props.type}
                           value={this.state.value}
                           autoComplete={'off'}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           onChange={this.handleChange}
                           onKeyDown={this.handleKeydown}
                           onFocus={this.handleFocus}
                    />
                    {suggestions}
                </div>
            </div>
        );
    }
}