import React from 'react';

import ProfileCard from "../../profile-card";

class CustomerCard extends React.Component {

  static defaultProps = {
    customer: {},
    showAddress: true,
    onClick: () => {}
  };

  render() {

    return (
      <div onClick={() => this.props.onClick(this.props.customer)}>
        <ProfileCard
          firstName={this.props.customer.first_name}
          lastName={this.props.customer.last_name}
          email={this.props.customer.email}
          telephone={this.props.customer.telephone}
          street={this.props.customer.address_street}
          number={this.props.customer.address_street_number}
          postalCode={this.props.customer.address_postal_code}
          city={this.props.customer.address_city}
          country={this.props.customer.address_country}
          showAddress={this.props.showAddress}
        />
      </div>
    );
  }
}

export default CustomerCard;