import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class Discount extends React.Component {
    static defaultProps = {
        title: 'Discount',
        modifiers: null
    };

    render() {
        return (
            <div className={'discount' + modifiers('discount', this.props.modifiers)}>
                <div className="discount__title">
                    {this.props.title}
                </div>
                <div className="discount__content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Discount;