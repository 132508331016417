export default {
  getUnixTime(date) {
    return date.getTime()/1000;
  },
  today() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
  },
  todayUnixTime() {
    return this.getUnixTime(this.today());
  },
  nowHourAndMinutes() {
    return (new Date()).toTimeString().substr(0,5);
  },
  firstDayOfMonth() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0);
  },
  firstDayOfMonthUnixTime() {
    return this.getUnixTime(this.firstDayOfMonth());
  },
  liesInTheFuture(timestamp) {
    return timestamp > this.todayUnixTime();
  }
}