import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

import Icon from '../icon/index';

export default class Button extends React.Component {
    
    static defaultProps = {
        text: '',
        icon: null,
        modifiers: null,
        active: false,
        type: 'button',
        style: null,
        onClick: () => {
        },
    };
    
    constructor(props) {
        super(props);
    }
    
    render() {
        let icon;
        
        if (this.props.icon) {
            icon = (
                <div className="button__icon">
                    {(this.props.modifiers.includes('outline') || this.props.modifiers.includes('transparent')) ? (
                            <Icon type={this.props.icon}/>
                        ) :
                        <Icon modifiers={['extra-dim']} type={this.props.icon}/>
                    }
                </div>
            );
        }
        
        return (
            <button
                type={this.props.type}
                className={'button' + modifiers('button', this.props.modifiers)}
                onClick={(event) => this.props.onClick(event)}
                style={this.props.style}
                value={this.props.text}
            >
                {icon}
                {this.props.text}
            </button>
        );
    }
}