import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class FilterGroup extends React.Component {

  static defaultProps = {
    'title': null,
    'modifiers': [],
  };

  render() {

    let title;

    if (this.props.title) {
      title = (
        <div className="filter-group__title">
          {this.props.title}
        </div>
      )
    }

    return (
      <div className={'filter-group'+modifiers('filter-group', this.props.modifiers)}>
        {title}
        <div className="filter-group__content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default FilterGroup;