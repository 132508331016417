import connect from "react-redux/es/connect/connect";
import { exportList, updateList } from "../../../../state/modules/resources";

import React from 'react';
import time from "../../../../util/time";

import Window from "../../window/index";
import List from "../../list/index";
import OrderListItem from "../order-list-item/index";
import ButtonGroup from "../../button-group";
import FilterGroup from "../../filter-group";
import EnumFilter from "../../enum-filter";
import Header from "../../header";
import HeaderColumn from "../../../atoms/header-column";
import Dropout from "../../dropout";
import FilterList from "../../filter-list";
import NumberWidget from "../../number-widget";
import Pagination from "../../pagination";
import Grid from "../../grid";
import Search from "../../search";
import DateRangeFilter from "../../date-range-filter";
import LinkButton from "../../../atoms/link-button";

class OrderIndex extends React.Component {

  static defaultProps = {
    onOrderView: () => {},
  };

  componentDidMount() {

    this.props.updateList('orders-month-total', {
      model: 'orders',
      filters: {
        filter_created: [
          {key: 'gte:'+time.firstDayOfMonthUnixTime()}
        ],
        filter_status: [
          {key: 1}
        ]
      }
    }, true);

    this.props.updateList('orders-today-total', {
      model: 'orders',
      filters: {
        filter_created: [
          {key: 'gte:'+time.todayUnixTime()}
        ],
        filter_status: [
          {key: 1}
        ]
      }
    }, true);
  }

  showWidgets() {

    if (! this.props.lists['orders']) {
      return true;
    }

    return (
        ! this.props.lists['orders'].search &&
        this.props.lists['orders'].pagination.currentPage === 1
    );
  }

  getListHeader() {

    return (
      <Header listId={'orders'}>
        <HeaderColumn title="Id" field={'sort_order_id'} defaultSorting={'desc'} />
        <HeaderColumn title="Date" field={'sort_created'} defaultSorting={'desc'} modifiers={'span-2'} />
        <HeaderColumn title="Products" modifiers={'span-7'} />
        <HeaderColumn title="Price" modifiers={'span-2'} />
        <HeaderColumn modifiers={'span-6'}/>
      </Header>
    );
  }

  getList() {
    return (
      <List id={'orders'}
            model={'orders'}
            filters={{
              filter_status: [{key: 1, value: "Paid"}],
              filter_fulfillment_method: [],
              filter_post_payment_status: []
            }}
            showHeader={true}
            header={this.getListHeader()}
            onItemClick={order => this.props.onOrderView(order)}
            childRef={ref => (this.child = ref)}
      >
        <OrderListItem />
      </List>
    );
  }

  render() {

    let widgets;

    if (this.showWidgets()) {

      let lists = this.props.lists;
      let totalMonthOrders = 0;

      if (lists['orders-month-total'] && lists['orders-month-total'].metadata && lists['orders-month-total'].metadata.count) {
        totalMonthOrders = lists['orders-month-total'].metadata.count;
      }

      let totalTodayOrders = 0;

      if (lists['orders-today-total'] && lists['orders-today-total'].metadata && lists['orders-today-total'].metadata.count) {
        totalTodayOrders = lists['orders-today-total'].metadata.count;
      }

      widgets = (
        <Grid cols="3fr 1fr 1fr">
          <Window modifiers={['depth', 'padded']}>
            <FilterGroup title="Shipping">
              <EnumFilter listId={'orders'} filterKey={'filter_fulfillment_method'} options={{ 0: 'Shipping', 1: 'Pickup', 2: 'None' }} />
            </FilterGroup>
            <FilterGroup title="Status">
              <EnumFilter
                  listId={'orders'}
                  filterKey={'filter_status'}
                  options={{
                    1: 'Paid',
                    0: 'Open',
                    5: 'Refunded',
                  }}
              />
            </FilterGroup>
            <FilterGroup title="Pre-order">
              <EnumFilter listId={'orders'} filterKey={'filter_has_preorder'} multiple={false} options={{ 0: 'No preorders', 1: 'Preorders' }} />
            </FilterGroup>
          </Window>
          <NumberWidget modifiers={'depth'} value={totalMonthOrders} title="This month order count"/>
          <NumberWidget modifiers={'depth'} value={totalTodayOrders} title="Today order count"/>
        </Grid>
      );
    }

    return (
      <Window
        title="Orders"
        header={
          <ButtonGroup>
            <FilterList values={{}} />
            <DateRangeFilter listId={'orders'} filterKey={'filter_created'} />
            <Dropout modifiers={'align-right'}>
              <Grid cols="1fr 1fr 1fr">
                {/*<FilterGroup title="Type" modifiers={'alternate-direction'}>
                  <EnumFilter modifiers={'alternate-direction'} listId={'orders'} filterKey={'filter_type'} options={{ 0: 'Store', 1: 'Online' }} />
                </FilterGroup>*/}
                <FilterGroup title="Shipping" modifiers={'alternate-direction'}>
                  <EnumFilter modifiers={'alternate-direction'} listId={'orders'} filterKey={'filter_fulfillment_method'} options={{ 0: 'Shipping', 1: 'Pickup', 2: 'None' }} />
                </FilterGroup>
                <FilterGroup title="Pre-order" modifiers={'alternate-direction'}>
                  <EnumFilter modifiers={'alternate-direction'} listId={'orders'} filterKey={'filter_has_preorder'} multiple={false} options={{ 0: 'No preorders', 1: 'Preorders' }} />
                </FilterGroup>
              </Grid>

              <FilterGroup title="Status">
                <EnumFilter
                  listId={'orders'}
                  filterKey={'filter_status'}
                  options={{
                    1: 'Paid',
                    0: 'Open',
                    2: 'Cancelled',
                    3: 'Failed',
                    4: 'Expired',
                    5: 'Refunded',
                  }}
                />
              </FilterGroup>
            </Dropout>
            <Search listId={'orders'} />
            <Dropout icon={'moreVertical'} modifiers={'align-right'}>
              <LinkButton onClick={e => this.props.exportList('orders')}>
                <span>Export data as csv</span>
              </LinkButton>
              <LinkButton onClick={e => this.props.exportList('orders', 'gfk')}>
                <span>Export Gfk data as csv</span>
              </LinkButton>
            </Dropout>
          </ButtonGroup>
        }
      >
        <Grid rows="auto 1fr">
          {widgets}
          <Window modifiers={'depth'} footer={<Pagination listId={'orders'} model={'orders'}/>}>
            {this.getList()}
          </Window>
        </Grid>
      </Window>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList,
  exportList: exportList
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderIndex);