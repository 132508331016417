import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

import IconButton from "../../atoms/icon-button";
import Button from "../../atoms/button";
import TextInput from "../../atoms/text-input";

class ActionInput extends React.Component {

    static defaultProps = {
        modifiers: ['negative'],
        buttonText: '',
        buttonIcon: null,
        placeholder: '',
        focus: false,
        default: '',
        onSubmit: () => {},
        onChange: () => {},
        onBlur: () => {},
        onFocus: () => {},
    };

    constructor(props) {
        super(props);

        this.field = React.createRef();
    }

    onSubmit(e) {
        e.preventDefault();

        let data = this.field.current.state.value;

        this.props.onSubmit(data, this.field.current);
    }

    onChange(e) {
        let data = this.field.current.state.value;

        this.props.onChange(data);
    }

    handleSubmit(data) {
        data[this.field.current.props.name] = this.field.current.state.value;
    }

    getButton() {
        if (this.props.buttonText) {
            return <Button
                text={this.props.buttonText}
                type='submit'
                icon={this.props.buttonIcon}
                onClick={e => this.onSubmit(e)}
            />
        }

        return <IconButton
            type='submit'
            iconType={this.props.buttonIcon}
            onClick={e => this.onSubmit(e)}
        />
    }

    getField() {
        return (
            <TextInput
                ref={this.field}
                default={this.props.default}
                placeholder={this.props.placeholder}
                modifiers={['rounded']}
                onChange={e => this.onChange(e)}
                onBlur={e => this.props.onBlur(e)}
                onFocus={e => this.props.onFocus(e)}
                focus={this.props.focus}
            />
        );
    }

    render() {
        return (
            <form
                className={"action-input" + modifiers('action-input', this.props.modifiers)}
                onSubmit={e => this.onSubmit(e)}>
                <div className="action-input__field">
                    {this.getField()}
                </div>
                <div className="action-input__button">
                    {this.getButton()}
                </div>
            </form>
        );
    }
}

export default ActionInput;