import React from 'react';

import { ReactComponent as House } from '../assets/svg/house.svg';
import { ReactComponent as Eye } from '../assets/svg/eye.svg';
import { ReactComponent as EyeOff } from '../assets/svg/eye-off.svg';
import { ReactComponent as Cd } from '../assets/svg/cd.svg';
import { ReactComponent as People } from '../assets/svg/people.svg';
import { ReactComponent as Archive } from '../assets/svg/archive.svg';
import { ReactComponent as First } from '../assets/svg/first.svg';
import { ReactComponent as Previous } from '../assets/svg/previous.svg';
import { ReactComponent as Next } from '../assets/svg/next.svg';
import { ReactComponent as Last } from '../assets/svg/last.svg';
import { ReactComponent as Add } from '../assets/svg/add.svg';
import { ReactComponent as Remove } from '../assets/svg/remove.svg';
import { ReactComponent as Delete } from '../assets/svg/delete.svg';
import { ReactComponent as AddCircle } from '../assets/svg/add-circle.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as Edit } from '../assets/svg/edit.svg';
import { ReactComponent as Search } from '../assets/svg/search.svg';
import { ReactComponent as Phone } from '../assets/svg/phone.svg';
import { ReactComponent as Mail } from '../assets/svg/mail.svg';
import { ReactComponent as DropUp } from '../assets/svg/drop-up.svg';
import { ReactComponent as DropDown } from '../assets/svg/drop-down.svg';
import { ReactComponent as Filter } from '../assets/svg/filter.svg';
import { ReactComponent as Person } from '../assets/svg/person.svg';
import { ReactComponent as Cart } from '../assets/svg/cart.svg';
import { ReactComponent as Scan } from '../assets/svg/scan.svg';
import { ReactComponent as Vinyl } from '../assets/svg/vinyl.svg';
import { ReactComponent as Calendar } from '../assets/svg/calendar.svg';
import { ReactComponent as MoreVertical } from '../assets/svg/more-vert.svg';
import { ReactComponent as StoreFront } from '../assets/svg/storefront.svg';
import { ReactComponent as Ship } from '../assets/svg/ship.svg';
import { ReactComponent as CashRegister } from '../assets/svg/cash-register.svg';
import { ReactComponent as Shut } from '../assets/svg/shut.svg';
import { ReactComponent as Print } from '../assets/svg/print.svg';
import { ReactComponent as List } from '../assets/svg/list.svg';
import { ReactComponent as Refresh } from '../assets/svg/refresh.svg';
import { ReactComponent as Save } from '../assets/svg/floppy-disk-solid.svg';
import { ReactComponent as Linked } from '../assets/svg/linked.svg';

const iconList = {
  house: <House />,
  eye: <Eye />,
  eyeOff: <EyeOff/>,
  cd: <Cd />,
  people: <People />,
  archive: <Archive />,
  first: <First />,
  previous: <Previous />,
  next: <Next />,
  last: <Last />,
  add: <Add />,
  addCircle: <AddCircle/>,
  remove: <Remove />,
  delete: <Delete />,
  close: <Close />,
  edit: <Edit />,
  search: <Search />,
  phone: <Phone />,
  mail: <Mail />,
  dropUp: <DropUp />,
  dropDown: <DropDown />,
  filter: <Filter />,
  person: <Person />,
  cart: <Cart/>,
  scan: <Scan/>,
  vinyl: <Vinyl/>,
  calendar: <Calendar/>,
  moreVertical: <MoreVertical/>,
  storeFront: <StoreFront/>,
  ship: <Ship/>,
  cashRegister: <CashRegister/>,
  shut: <Shut/>,
  print: <Print/>,
  list: <List/>,
  refresh: <Refresh/>,
  save: <Save/>,
  linked: <Linked />,
};

export default iconList;