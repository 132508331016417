import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import { validateForm } from "../../../../util/validation";
import ui from "../../../../util/ui";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";

class CustomerCreate extends React.Component {

  static defaultProps = {
    onCancel: () => {},
    onSave: () => {}
  };

  constructor(props) {

    super(props);

    this.form = React.createRef();
  }

  validateForm(form, data) {

    validateForm(form, data, {
      // email: ['ifEmpty:telephone', 'email'],
      email: [{'ifEmpty:telephone': [
        'required',
        'email'
      ]}],
      first_name: ['required'],
      last_name: ['required'],
      address_street: ['required'],
      address_street_number: ['required'],
      address_postal_code: ['required'],
      address_city: ['required'],
      address_country: ['required'],
      // telephone: ['requiredIfEmpty:email']
      telephone: [{'ifEmpty:email': [
        'required'
      ]}]
    });
  }

  onSubmit(customerData) {

    http.post(API_HOST+API_ROOT+'customers', customerData,
      true).then(response => {
        if (response.success) {
          this.props.onSave(response.result);
        }
    }).catch(error => {
      if (error.message === 'duplicate_customer') {
        ui.notify('A customer with that emailaddress already exists', 'error');
      }
    });
  }

  render() {

    return (
      <div>
        <Form
          ref={this.form}
          validate={(form, data) => this.validateForm(form, data)}
          submitButtonText={'Create customer'}
          onSubmit={(data) => this.onSubmit(data)}
        >
          <InputStack modifiers={'alternate-direction'} title={'Personal information'}>
            <TextInput name="email" label="Email Address" showRequiredIndicator={true} />
            <InputStack>
              <TextInput name="first_name" label="First name" showRequiredIndicator={true} />
              <TextInput name="last_name" label="Last name" showRequiredIndicator={true} />
            </InputStack>
            <TextInput name="telephone" label="Telephone" />
          </InputStack>
          <InputStack modifiers={'alternate-direction'} title={'Invoice data'}>
            <InputStack>
              <TextInput name="company_name" label="Company name" />
              <TextInput name="company_vat_number" label="VAT" />
            </InputStack>
            <InputStack>
              <TextInput name="address_street" label="Street" showRequiredIndicator={true} />
              <TextInput name="address_street_number" label="Number" showRequiredIndicator={true} />
            </InputStack>
            <TextInput name="address_postal_code" label="Postal code" showRequiredIndicator={true} />
            <TextInput name="address_city" label="City" showRequiredIndicator={true} />
            <TextInput name="address_country" label="Country" showRequiredIndicator={true} default={'Belgium'} disabled={true} />
          </InputStack>
        </Form>
      </div>
    );
  }
}

export default CustomerCreate;