import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

import Icon from "../icon";

export default class IconLabel extends React.Component {

  static defaultProps = {
    text: '',
    type: 'phone',
    link: null,
    modifiers: null,
    iconModifiers: null
  };

  render() {

    if (this.props.link) {
      return (
        <a className={'icon-label icon-label--link'+modifiers('icon-label', this.props.modifiers)} href={this.props.link} target="_blank" rel="noopener noreferrer">
          <span className="icon-label__icon">
            <Icon type={this.props.type} modifiers={this.props.iconModifiers} />
          </span>
          <span className="icon-label__text">
            {this.props.text}
          </span>
        </a>
      );
    }

    return (
      <span className={'icon-label'+modifiers('icon-label', this.props.modifiers)}>
        <span className="icon-label__icon">
          <Icon type={this.props.type} modifiers={this.props.iconModifiers} />
        </span>
        <span className="icon-label__text">
          {this.props.text}
        </span>
      </span>
    );
  }
}