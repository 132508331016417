import './index.scss';

import { connect } from "react-redux";
import { setActiveModule } from "../../../state/modules/modules";

import React from "react";

import Icon from "../../atoms/icon";

class ModuleNav extends React.Component {

  static defaultProps = {
    items: []
  };

  getItems() {

    return Object.entries(this.props.items).map(([key, item]) => {
      return (
        <li key={key} className={'module-nav__item'+(key === this.props.activeModule ? ' module-nav__item--active' : '')}>
         <button className="module-nav__item-button" onClick={() => this.props.setActiveModule(key)}>
           <Icon type={item.icon} modifiers={['large']} />
           <span className="module-nav__item-title">
             {item.title}
           </span>
         </button>
        </li>
      );
    });
  }

  render() {

    return (
      <div className="module-nav">
        <ul className="module-nav__list">
          {this.getItems()}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ activeModule: state.modules.activeModule });
const mapDispatchToProps = { setActiveModule: setActiveModule };

export default connect(mapStateToProps, mapDispatchToProps)(ModuleNav);