import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

class LinkButton extends React.Component {

  static defaultProps = {
    'modifiers': null,
    'type': 'button',
    onClick: () => {}
  };

  render() {

    return (
      <button type={this.props.type} className={'link-button'+modifiers('link-button', this.props.modifiers)} onClick={this.props.onClick}>
        {this.props.children}
      </button>
    );
  }
}

export default LinkButton;