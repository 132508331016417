import './index.scss';

import connect from "react-redux/es/connect/connect";
import {updateList} from "../../../state/modules/resources";

import React from 'react';

import IconButton from "../../atoms/icon-button";

class PaginationArrows extends React.Component {

  static defaultProps = {
    listId: null,
    model: null
  };

  goTo(page) {

    this.props.updateList(this.props.listId, {
      pagination: {
        currentPage: page
      }
    });
  }

  getPaginationData() {

    return this.props.lists[this.props.listId] ? this.props.lists[this.props.listId].pagination : null;
  }

  render() {

    let list = this.props.lists[this.props.listId];

    if (! list) {
      return null;
    }

    let { items } = list;

    if (! items.length) {
      return null;
    }

    let data = this.getPaginationData();

    return (
      <div className="pagination-arrows">
        <div className="pagination-arrows__item">
          <IconButton modifiers="alt" iconType="previous" onClick={e => this.goTo(Math.max(1, data.currentPage-1))} />
        </div>
        <div className="pagination-arrows__item">
          <IconButton modifiers="alt" iconType="next" onClick={e => this.goTo(Math.min(data.currentPage+1, data.pageCount))} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginationArrows);