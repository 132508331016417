export default {
  queryParams(params) {
    return Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&')
    ;
  },
  urlWithQuery(url, params) {
    return url+'?'+this.queryParams(params);
  }
};