import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class Label extends React.Component {

  static defaultProps = {
    modifiers: '',
    title: '',
    link: null,
  };

  render() {

    if (this.props.link) {
      return (
        <a href={this.props.link} target="_blank" className={'label'+modifiers('label', this.props.modifiers)} rel="noopener noreferrer">
          {this.props.title}
        </a>
      );
    }

    return (
      <span className={'label'+modifiers('label', this.props.modifiers)}>
        {this.props.title}
      </span>
    );
  }
}

export default Label;