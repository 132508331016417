import React from 'react';

import Photo from "../../../atoms/photo";
import IconButton from "../../../atoms/icon-button";
import CustomerCard from "../../customer/customer-card";
import Grid from "../../grid";
import Window from "../../window";

class CartListItem extends React.Component {

  static defaultProps = {
    model: {},
    onDelete: () => {}
  };

  getFooter() {

    return (
      <Grid cols={'repeat(5, 1fr)'} modifiers={'padded-alternate-direction'}>
        {this.props.model.items ? this.props.model.items.map((item, i) => {
          return (
            <Photo key={i} src={item.buyable.thumbnail_url} modifiers={'round'} />
          );
        }).reverse() : null}
      </Grid>
    );
  }

  getHeader() {

    return (
      <IconButton modifiers={'alt'} iconType={'delete'} onClick={e => this.props.onDelete(this.props.model)} />
    );
  }

  render() {

    return (
      <Window title={'Cart #'+this.props.model.id+' (€ '+this.props.model.total.toFixed(2)+')'} modifiers={['padded', 'frame']} header={this.getHeader()}>
        {this.props.model.customer ? <CustomerCard showAddress={false} customer={this.props.model.customer} /> : ''}
        {this.getFooter()}
      </Window>
    );
  }
}

export default CartListItem;