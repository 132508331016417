import React, { Component } from "react";
import { connect } from "react-redux";
import { me as fetchMe } from "./state/modules/auth";

import Login from "./components/organisms/login";
import BaseLayout from "./components/organisms/base-layout";

import "./App.scss";
import Loader from "./components/atoms/loader";

class App extends Component {

  componentDidMount() {
    this.props.fetchMe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.props.fetchMe();
    }
  }

  render() {

    if (this.props.isLoading) {
      return (
        <div className="app-loader">
          <Loader isLoading={true} size="large"/>
        </div>
      );
    }

    // Not logged in
    let view = <Login />;
    let test = null;

    if (this.props.isAuthenticated) {
      view = (
        <div>
          <BaseLayout modules={this.props.modules} active={this.props.activeModule} />
        </div>
      );
    }
    
    if (process.env.REACT_APP_WEB_HOST !== 'https://www.musicmaniarecords.be') {
      test = (
          <div className="app-note">
            POS TEST MODE
          </div>
      );
    }

    return (
      <div className="app">
        {test}
        {view}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    modules: state.modules.modules,
    activeModule: state.modules.activeModule,
    isLoading: state.utils.isLoading
  }
};

const mapDispatchToProps = { fetchMe };

export default connect(mapStateToProps, mapDispatchToProps)(App);
