import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

import IconButton from "../../atoms/icon-button";
import Window from "../window";
import Button from "../../atoms/button";

class Dropout extends React.Component
{
  static defaultProps = {
    title: null,
    icon: 'filter',
    modifiers: null,
    buttonModifiers: null,
  };

  constructor(props) {

    super(props);

    this.state = {
      isOpen: false
    };

    this.dropoutContainer = React.createRef();

    this.handleDocumentClick = e => {
      if (this.dropoutContainer.current && ! this.dropoutContainer.current.contains(e.target)) {
        this.toggle();
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.isOpen && ! prevState.isOpen) {
      document.addEventListener('click', this.handleDocumentClick);
    }

    if (! this.state.isOpen && prevState.isOpen) {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  }

  toggle() {

    this.setState({ isOpen: ! this.state.isOpen });
  }

  render() {

    let content;
    let button = <IconButton onClick={e => this.toggle()} iconType={this.props.icon} modifiers="button" />;

    if (this.props.title) {
      button = <Button modifiers={['reverse-order', 'small', ...this.props.buttonModifiers]} icon={this.props.icon} text={this.props.title} onClick={e => this.toggle()} />
    }

    if (this.state.isOpen) {

      content = (
        <div ref={this.dropoutContainer} className="dropout__content">
          <Window>
            {this.props.children.length ?
              this.props.children.map((child, key) => <div key={key}>{child}</div>) :
              <div>{this.props.children}</div>
            }
          </Window>
        </div>
      );
    }

    return (
      <div className={'dropout'+modifiers('dropout', this.props.modifiers)}>
        {button}
        {content}
      </div>
    );
  }
}

export default Dropout;