import React from 'react';
import { photoSrc } from "../../../../util/media";
import { price } from "../../../../util/formatting";
import {orderLabelModifier} from "../../../../util/label-colors";
import StatusLabel from "../../../atoms/status-label";

class OrderItems extends React.Component
{
  static defaultProps = {
    items: [],
    thumbnail_url: 'thumbnail_url',
    title: 'title',
    subtitle: 'subtitle',
    artist: 'artist',
    price: 'price',
    formats: 'formats',
    is_preordered: 'is_preordered',
  };

  getProperty(path, obj) {
    return path.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : null
    }, obj)
  }

  render() {

    if (! this.props.items) {
      return <div></div>;
    }

    return (
      <div className='list'>
        <div className="list__items">
          {this.props.items.map((item, key) => {
            {
              if (this.getProperty('product_variation', item) !== undefined) {
                return (
                    <div key={key} className="list__item">
                      <div className="list-item">
                        <div className="list-item__thumb">
                          <img src={photoSrc(this.getProperty(this.props.thumbnail_url, item))} alt={this.getProperty(this.props.name, item) + ' - ' + this.getProperty(this.props.subtitle, item)}/>
                        </div>
                        <div className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-5">
                          {this.getProperty(this.props.name, item)} <StatusLabel modifiers={['error', 'compact']} title={this.getProperty(this.props.subtitle, item)}/><br />
                          {this.getProperty(this.props.artist, item) && (<span>{this.getProperty(this.props.artist, item)}<br /></span>)}
                          {price(this.getProperty(this.props.price, item))}
                        </div>
                        {this.getProperty(this.props.is_preordered, item) === true &&
                          <div className="list-item__column list-item__column--valign-top list-item__column--clean">
                            <StatusLabel title="Preorder" />
                          </div>
                        }
                      </div>
                    </div>
                );
              }

              else {
                return (
                    <div key={key} className="list__item">
                      <div className="list-item">
                        <div className="list-item__thumb">
                          <img src={photoSrc(this.getProperty(this.props.thumbnail_url, item))} alt={this.getProperty(this.props.title, item)}/>
                        </div>
                        <div className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-6">
                          {this.getProperty(this.props.title, item)}<br />
                          {this.getProperty(this.props.subtitle, item) && (<span>{this.getProperty(this.props.subtitle, item)}<br /></span>)}
                          {price(this.getProperty(this.props.price, item))}
                        </div>
                        {this.getProperty(this.props.formats, item) && this.getProperty(this.props.formats, item).map((item, key) => {
                          return (
                              <div className="list-item__column list-item__column--valign-top list-item__column--clean">
                                <StatusLabel title={item.abbreviation} />

                                {this.getProperty(this.props.is_preordered, item) === true &&
                                    <div>
                                      <br/>
                                      <StatusLabel title="Preorder" />
                                    </div>
                                }
                              </div>
                          )
                        })}
                      </div>
                    </div>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default OrderItems;