import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class NumberWidget extends React.Component {

  static defaultProps = {
    value: '',
    title: '',
    prefix: '',
    suffix: '',
  };

  render() {
    return (
      <div className={'number-widget'+modifiers('number-widget', this.props.modifiers)}>
        <div className="number-widget__value">
          {this.props.prefix ? this.props.prefix + ' ' : ''}
          {(''+this.props.value).replace('.', ',')}
          {this.props.suffix ? ' '+this.props.suffix : ''}
        </div>
        <div className="number-widget__title">
          {this.props.title}
        </div>
      </div>
    );
  }
}

export default NumberWidget;