import './index.scss';

import React from 'react';

class ButtonGroup extends React.Component
{
  static defaultProps = {
    children: []
  };

  render() {

    return (
      <div className="button-group">
        {this.props.children.length > 1 ? (

          this.props.children.map((child, index)  => {
            return (
              <div key={index} className="button-group__item">
                {child}
              </div>
            );
          })
          ) : (
            <div className="button-group__item">
              {this.props.children}
            </div>
          )
        }
      </div>
    );
  }
}

export default ButtonGroup;