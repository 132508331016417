import './index.scss';

import connect from "react-redux/es/connect/connect";
import { logout } from "../../../state/modules/auth";

import React from 'react';

import ModuleNav from "../../../components/molecules/module-nav";
import Logo from "../../atoms/logo";
import Dropout from "../../molecules/dropout";

class BaseLayout extends React.Component
{
  getModuleView() {

    let activeModule = Object.keys(this.props.modules).find(module => module === this.props.active);
    return this.props.modules[activeModule].view;
  }

  render() {

    return (
      <div className="base-layout">
        <div className="base-layout__sidebar">
          <div className="base-layout__logo">
            <Logo modifiers={['compact', 'simple']} />
          </div>
          <ModuleNav items={this.props.modules}/>
          <span>{this.props.me.firstName}</span>
          <Dropout icon={'person'}>
            <button type='button' onClick={this.props.logout}>logout</button>
          </Dropout>
        </div>
        <div className="base-layout__main">
          {this.getModuleView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.auth.me
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);