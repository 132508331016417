import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

class StatusLabel extends React.Component {

  static defaultProps = {
    modifiers: '',
    title: ''
  };

  render() {

    return (
      <span className={'status-label'+modifiers('status-label', this.props.modifiers)}>
        {this.props.title}
      </span>
    );
  }
}

export default StatusLabel;