import connect from "react-redux/es/connect/connect";
import {updateList} from "../../../state/modules/resources";
import {handleTokenErrors} from "../../../state/modules/errors";

import React from 'react';
import {price} from "../../../util/formatting";
import time from "../../../util/time";

import List from "../list";
import OrderListItem from "../order/order-list-item";
import Window from "../window";
import NumberWidget from "../number-widget";
import Grid from "../grid";
import BarChart from "../bar-chart";
import {API_HOST, API_ROOT, http} from "../../../util/http";
import EnumSwitch from "../enum-switch";
import ButtonGroup from "../button-group";

class DashboardView extends React.Component {
    
    orderTotals = [];
    maxValue = 0;
    stepSize = 1000
    
    constructor(props) {
        super(props);
        
        let currentYear = new Date().getFullYear();
        
        let years = [];
        
        for (let year = 2018; year <= currentYear; year++) {
            years.push({
                key: year,
                label: '' + year + ''
            });
        }
        
        this.state = {
            activeResultType: 'monthly',
            activeOrderTotalsYear: currentYear,
            activeOrderType: '',
            years: years,
            totalsActive: '',
            totalsValue: 0,
            totalsCount: 0,
            totalsLabel: '',
        }
    }
    
    componentDidMount() {
        this.props.updateList('orders-month-total', {
            model: 'orders',
            filters: {
                filter_created: [
                    {key: 'gte:' + time.firstDayOfMonthUnixTime()}
                ],
                filter_status: [
                    {key: 1}
                ]
            },
            limit: 2500
        });
        
        this.props.updateList('orders-today-total', {
            model: 'orders',
            filters: {
                filter_created: [
                    {key: 'gte:' + time.todayUnixTime()}
                ],
                filter_status: [
                    {key: 1}
                ]
            },
            limit: 75
        });
        
        this.fetchOrderTotals();
    }

    toggleActive(options) {
        let {
            year = this.state.activeOrderTotalsYear,
            resultType = this.state.activeResultType,
            type = this.state.activeOrderType,
            item = this.state.totalsActive,
        } = options;

        http
            .get(API_HOST + API_ROOT + 'orders/tileTotals', true, {
                year: this.state.activeOrderTotalsYear,
                resultType: this.state.activeResultType,
                type: this.state.activeOrderType,
                selection: item,
            })
            .then(response => {
                this.setState({
                    totalsActive: item,
                    totalsValue: parseFloat(response.result.value),
                    totalsCount: parseFloat(response.result.count),
                    totalsLabel: response.result.label,
                });
            })
            .catch(error => handleTokenErrors(error));
    }
    
    fetchOrderTotals(options = {}) {
        let {
            year = this.state.activeOrderTotalsYear,
            resultType = this.state.activeResultType,
            type = this.state.activeOrderType,
            item = null,
        } = options;

        http
            .get(API_HOST + API_ROOT + 'orders/totals', true, {
                year: year,
                resultType: resultType,
                type: type,
              })
            .then(response => {
                this.orderTotals = response.result;
                
                if (resultType === 'yearly') {
                    this.stepSize = 25000;
                }

                if (resultType === 'monthly') {
                    this.stepSize = 5000;
                }

                if (resultType === 'weekly') {
                    this.stepSize = 1000;
                }
                
                if (resultType === 'daily') {
                    this.stepSize = 250;
                }

                this.setState({
                    activeOrderTotalsYear: year,
                    activeOrderType: type,
                    activeResultType: resultType
                });

                this.toggleActive({
                    year: year,
                    resultType: resultType,
                    type: type,
                    item: item,
                });
            })
            .catch(error => handleTokenErrors(error));
    }
    
    render() {
        const calculateTotalPrice = ( accumulator, {total_price}) => {
            return accumulator + parseFloat(total_price);
        };
        
        let totalMonthOrders = this.props.lists['orders-month-total'];
        let totalMonthOrdersCount = 0;
        let revenueThisMonth = 0;
        
        if (totalMonthOrders && totalMonthOrders.items && totalMonthOrders.items.length) {
            totalMonthOrdersCount = totalMonthOrders.items.length;
            revenueThisMonth = totalMonthOrders.items.reduce(calculateTotalPrice, 0);
        }
        
        let totalTodayOrders = this.props.lists['orders-today-total'];
        let totalTodayOrdersCount = 0;
        let revenueToday = 0;
        
        if (totalTodayOrders && totalTodayOrders.items && totalTodayOrders.items.length) {
            totalTodayOrdersCount = totalTodayOrders.items.length;
            revenueToday = totalTodayOrders.items.reduce(calculateTotalPrice, 0);
        }

        return (
            <Window title={'Welkom terug ' + this.props.me.first_name}>
                <Grid cols="3fr 1fr">
                    <Grid rows="2fr 4fr">
                        <Window
                            modifiers={['depth']}
                            header={
                                <ButtonGroup>
                                    <EnumSwitch
                                        activeKey={this.state.activeResultType}
                                        values={[
                                            {
                                                key: 'yearly',
                                                label: 'Yearly'
                                            },
                                            {
                                                key: 'monthly',
                                                label: 'Monthly'
                                            },
                                            {
                                                key: 'weekly',
                                                label: 'Weekly'
                                            },
                                            {
                                                key: 'daily',
                                                label: 'Daily'
                                            },
                                        ]}
                                        onItemSelect={key => this.fetchOrderTotals({resultType: key})}
                                    />
                                    {this.state.activeResultType === 'monthly' &&
                                    <EnumSwitch
                                        activeKey={this.state.activeOrderTotalsYear}
                                        values={this.state.years}
                                        onItemSelect={key => this.fetchOrderTotals({year: key})}
                                    />
                                    }
                                    <EnumSwitch
                                        activeKey={this.state.activeOrderType}
                                        values={[
                                            {
                                                key: '',
                                                label: 'Both'
                                            },
                                            {
                                                key: 0,
                                                label: 'Store'
                                            },
                                            {
                                                key: 1,
                                                label: 'Online'
                                            }
                                        ]}
                                        onItemSelect={key => this.fetchOrderTotals({type: key})}
                                    />
                                </ButtonGroup>
                            }
                        >
                            <BarChart stepSize={this.stepSize} data={this.orderTotals} onItemSelect={key => this.toggleActive({item: key})} />
                        </Window>
                        <Window title="Recent orders" modifiers="depth">
                            <List id={'recents-orders'} model={'orders'} limit={8} selectable={false}>
                                <OrderListItem/>
                            </List>
                        </Window>
                    </Grid>
                    <Grid>
                        <NumberWidget modifiers={'depth'} value={price(this.state.totalsValue.toFixed(2))} title={'Revenue ' + this.state.totalsLabel}/>
                        <NumberWidget modifiers={'depth'} value={this.state.totalsCount} title={'Order count ' + this.state.totalsLabel}/>
                        <NumberWidget modifiers={'depth'} value={price(revenueToday.toFixed(2))} title="Revenue today"/>
                        <NumberWidget modifiers={'depth'} value={totalTodayOrdersCount} title="Today orders count"/>
                        <NumberWidget modifiers={'depth'} value={totalMonthOrdersCount} title="This month orders count"/>
                        <NumberWidget modifiers={'depth'} value={price(revenueThisMonth.toFixed(2))}
                                      title="Revenue this month"/>
                    </Grid>
                </Grid>
            </Window>
        );
    }
}

const mapStateToProps = (state) => ({
    lists: state.resources.lists,
    me: state.auth.me
});

const mapDispatchToProps = {
    updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);