import { API_HOST, API_ROOT, http } from "../../util/http";
import store from '../store/index';
import download from "../../util/export";
import {handleTokenErrors} from "./errors";
import Localstorage from '../../util/local-storage';

/*
  Constants
 */
export const SET_LIST = "resources/SET_LIST";

/*
  Actions Creators
 */

function addOptsToParams(opts, params = '') {

  Object.entries(opts).forEach(([key, value]) => {

    if (value === '' || (Array.isArray(value) && ! value.length) ) {
      return;
    }

    if (Array.isArray(value)) {

      params += '&'+key+'=';

      value.forEach((v, index, value) => {

        params += v.key;

        if (index !== value.length-1) {
          params += '-';
        }
      });

    } else if (value !== null && typeof value !== 'object') {
      params += '&'+key+'='+value;

    } else if (value && typeof value === 'object') {
      params = addOptsToParams(value, params);
    }
  });

  return params;
}

export function updateList(listId, opts = {}, info = false) {

  return (dispatch) => {

    // set initial state
    dispatch(setList(listId, {...opts, isLoading: true}));

    // get lists from state and unpack
    const lists = store.getState().resources.lists;

    let { pagination, limit, search, filters, sort } = lists[listId];

    if (Localstorage.get('limit-'+listId)) {
      limit = Localstorage.get('limit-'+listId);
    }
    
    // get params from list state for http request
    let params = addOptsToParams({
      limit: limit || null,
      search: search || null,
      filters: filters || null,
      sort: sort || null,
      page: (pagination && pagination.currentPage) || null
    });

    return http.request({
      url: API_HOST+API_ROOT+lists[listId].model+(info ? '/info' : '')+'?'+params.substr(1),
    }, true)
      .then(response => {

        let { items, pagination, metadata } = response.result;

        // update state
        dispatch(setList(listId, {
          items: items,
          pagination: pagination,
          metadata: metadata,
          isLoading: false
        }));
      })
      .catch(error => {
        handleTokenErrors(error);
      });
  };
}

export function exportList(listId, exportType = null) {

  // get lists from state and unpack
  const lists = store.getState().resources.lists;

  let { search, filters, sort } = lists[listId];

  // get params from list state for http request
  let params = addOptsToParams({
    search: search || null,
    filters: filters || null,
    sort: sort || null,
  });

  return (dispatch) => {

    let exportString = 'export'+(exportType ? '-'+exportType : '');

    return http.request({url: API_HOST+API_ROOT+lists[listId].model+'/'+exportString+'?'+params.substr(1) } , true)
      .then(response => {

        const blob = new Blob(
          [ response.result.data ],
          { type: 'text/csv' }
        );

        download(blob, response.result.filename);
      })
      .catch(error => handleTokenErrors(error))
  };
}

export function setList(listId, opts) {
  return {
    type: SET_LIST,
    payload: {
      listId: listId,
      opts: opts
    }
  }
}

/*
  Initial State
 */
const initialState = {
  lists: {}
};

/*
  Reducers
 */
export default function reducer(state = initialState, action) {

  switch (action.type) {

    case SET_LIST: {

      let lists = addOrUpdateList(state.lists, action.payload.listId, action.payload.opts);

      return {
        ...state,
        lists
      }
    }

    default:
      return state;

  }
};

function addOrUpdateList(lists, listId, opts) {

  let newLists = {...lists};

  // create list
  if (! newLists[listId]) {
    newLists[listId] = opts;
    return newLists;
  }

  // update list
  newLists[listId].filters = {...newLists[listId].filters, ...opts.filters};
  newLists[listId].pagination = {...newLists[listId].pagination, ...opts.pagination};

  if ('search' in opts) {
    newLists[listId].search = opts.search;
  }

  if (opts.sort) {
    newLists[listId].sort = opts.sort;
  }

  if (Localstorage.get('limit-'+listId)) {
    newLists[listId].limit = Localstorage.get('limit-'+listId);
  }

  if ('limit' in opts && opts.limit) {
    newLists[listId].limit = opts.limit;
    Localstorage.set('limit-'+listId, opts.limit);
  }

  if (opts.items) {
    newLists[listId].items = opts.items;
  }

  if (opts.metadata) {
    newLists[listId].metadata = opts.metadata;
  }

  newLists[listId].isLoading = opts.isLoading;

  return newLists;
}