import './index.scss';

import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../state/modules/resources";

import React from 'react';

import Checkbox from "../../atoms/checkbox";
import modifiers from "../../../util/components";

class EnumFilter extends React.Component {

  static defaultProps = {
    listId: null,
    filterKey: '',
    options: {},
    multiple: true
  };

  constructor(props) {

    super(props);

    this.state = { activeKeys: [] };

    this.checkboxRefs = [];

    this.options = Object.entries(this.props.options);

    this.options.forEach(option => {
      this.checkboxRefs.push(React.createRef());
    });
  }

  componentDidUpdate(prevProps) {

    if (this.props.lists[this.props.listId] !== prevProps.lists[this.props.listId]) {
      this.setState({ activeKeys: this.props.lists[this.props.listId].filters[this.props.filterKey] || [] });
    }
  }

  componentDidMount() {

    if (this.props.lists[this.props.listId]) {
      this.setState({ activeKeys: this.props.lists[this.props.listId].filters[this.props.filterKey] || [] });
    }
  }

  handleChange(name, label, checked, index) {

    if (! this.props.multiple) {

      let activeKeys = [];

      this.checkboxRefs.forEach(ref => {
        ref.current.setValue(false);
      });

      // Unset if already checked
      if (! this.checkboxRefs[index].current.getValue()) {
        this.checkboxRefs[index].current.setValue(true);
        activeKeys.push({ key: parseInt(name), value: label });
      }

      this.updateActiveKeys(activeKeys);
      return;
    }

    let activeKeys = [...this.state.activeKeys];
    let found = activeKeys.find(activeKey => activeKey.key === parseInt(name));

    if (found && !checked) {
      activeKeys = activeKeys.filter(activeKey => activeKey.key !== found.key);

    } else if (checked) {
      activeKeys.push({ key: parseInt(name), value: label });
    }

    this.updateActiveKeys(activeKeys);
  }

  updateActiveKeys(activeKeys) {

    this.props.updateList(this.props.listId, {
      filters: {
        [this.props.filterKey]: activeKeys
      }
    });

    this.setState({ activeKeys: activeKeys });
  }

  isChecked(key) {

    return this.state.activeKeys.find(activeKey => activeKey.key === parseInt(key));
  }

  render() {

    let values = this.options.map(([name, label], index) => {
      return (
        <div key={name} className="enum-filter__item">
          <Checkbox ref={this.checkboxRefs[index]} onChange={(checked) => this.handleChange(name, label, checked, index)} label={label} name={name} default={this.isChecked(name)}/>
        </div>
      );
    });

    return (
      <div className={'enum-filter'+modifiers('enum-filter', this.props.modifiers)}>
        {values}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(EnumFilter);