import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

import Icon from '../icon/index';

export default class ActionButton extends React.Component {
    
    static defaultProps = {
        text: '',
        icon: null,
        modifiers: null,
        active: false,
        type: 'button',
        style: null,
        onClick: () => {
        },
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            disabled: false,
        };
        
        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick(event) {
        console.log('click');
        
        if (this.state.disabled) {
            return;
        }
        
        this.setState({
            disabled: true,
        });
        
        await this.executeClick(event)
            .finally(() => {
                this.setState({
                    disabled: false,
                });
            });
    }
    
    executeClick(event) {
        return new Promise(resolve => {
            this.props.onClick(event, resolve);
        });
    }
    
    render() {
        let icon;
        
        if (this.props.icon) {
            icon = (
                <div className="button__icon">
                    {(this.props.modifiers.includes('outline') || this.props.modifiers.includes('transparent')) ? (
                            <Icon type={this.props.icon}/>
                        ) :
                        <Icon modifiers={['extra-dim']} type={this.props.icon}/>
                    }
                </div>
            );
        }
        
        return (
            <button
                type={this.props.type}
                className={'button' + modifiers('button', this.props.modifiers) + (this.state.disabled ? ' button--disabled' : '')}
                onClick={this.handleClick}
                disabled={this.state.disabled}
                style={this.props.style}
                value={this.props.text}
            >
                {icon}
                {this.props.text}
            </button>
        );
    }
}