import './index.scss';

import React from 'react';
import Loader from "../../atoms/loader";

class LoadingOverlay extends React.Component
{
  static defaultProps = {
    isLoading: false
  };

  render() {

    return (
      <div className={'loading-overlay'+(this.props.isLoading ? ' loading-overlay--loading' : '')}>
        <Loader isLoading={true} size={'large'} />
      </div>
    );
  }
}

export default LoadingOverlay;