import React from 'react';
import { photoSrc } from "../../../../util/media";
import { date, price } from "../../../../util/formatting";

import IconLabel from "../../../atoms/icon-label";
import Label from "../../../atoms/label";
import IconButton from "../../../atoms/icon-button";
import StatusLabel from "../../../atoms/status-label";
import Dropout from "../../dropout";

class ReleaseListItem extends React.Component {

  static defaultProps = {
    model: {},
    onManageStock: () => {}
  };

  render() {

    let formats;
    let webshopLink;

    if (this.props.model.formats_string) {
      formats = <StatusLabel modifiers={['error', 'compact']} title={this.props.model.formats_string}/>;
    }

    if (this.props.model.is_published) {
      webshopLink = <IconLabel
        type={'eye'}
        text={'View online'}
        link={process.env.REACT_APP_WEB_HOST+'/'+this.props.model.artist.id+'-'+this.props.model.artist.slug+'/'+this.props.model.id+'-'+this.props.model.slug+'/'}
      />;
    }

    return (
      <div className="list-item">
        <div className="list-item__column list-item__column--clean">
          <Dropout icon={this.props.model.is_published ? 'eye' : 'eyeOff'}>
            {webshopLink}
            <IconLabel
                type={'edit'}
                text={'Manage'}
                link={process.env.REACT_APP_WEB_HOST+'/backend/shop/releases/edit:'+this.props.model.id+'/'}
            />
          </Dropout>
        </div>
        <div className="list-item__thumb">
          <img src={photoSrc(this.props.model.thumbnail_url)} alt={this.props.model.title} />
        </div>
        <div className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-5">
          {this.props.model.title} {formats}<br />
          {this.props.model.artist.name}<br />
          {price(this.props.model.price)}
        </div>
        <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
          Barcode: {this.props.model.barcode}<br />
          {this.props.model.label && (
            <div>
              <IconLabel text={this.props.model.label} iconModifiers={'small'} type="vinyl"/><br />
            </div>
          )}
          {this.props.model.release_date > 0 && (
            <IconLabel text={date(this.props.model.release_date)} type={'calendar'} iconModifiers={'small'}/>
          )}
        </div>
        <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
          Offline stock: {this.props.model.stock_2 ? this.props.model.stock_2 : 0}<br />
          Online stock: {this.props.model.stock ? this.props.model.stock : 0}
          {this.props.model.is_preorderable && <div><Label title="Preorderable"/></div>}
        </div>
        <div className="list-item__column">
          <IconButton iconType={'edit'} onClick={() => this.props.onManageStock(this.props.model)}/>
        </div>
      </div>
    );
  }
}

export default ReleaseListItem;