import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

import iconList from "../../../util/icon-list";

export default class Icon extends React.Component {

  static defaultProps = {
    type: null,
    modifiers: null
  };

  render() {

    if (! this.props.type) {
      return <span></span>;
    }

    return (
      <span className={'icon'+modifiers('icon', this.props.modifiers)}>
        {iconList[this.props.type]}
      </span>
    );
  }
}