import './index.scss';

import React from 'react';
import {API_HOST, API_ROOT, http} from "../../../util/http";
import ui from "../../../util/ui";

import Form from "../form";
import TextInput from "../../atoms/text-input";
import Button from "../../atoms/button";
import Loader from "../../atoms/loader";
import {handleTokenErrors} from "../../../state/modules/errors";

class BarcodeScanner extends React.Component {

    static defaultProps = {
        defaultValues: null,
        showErrors: true,
        isLoading: false,
        isScanning: false,
        list: 'releases',
        onReleaseFound: (release) => {},
        onMultipleReleasesFound: (releases, barcode) => {},
        onProductFound: (product) => {},
        onReleaseNotFound: (barcode) => {},
        onOrderFound: (order) => {},
        onMultipleOrdersFound: (orders, barcode) => {},
        onOrderNotFound: (order) => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            isScanning: this.props.isScanning
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isScanning !== prevProps.isScanning) {
            this.toggleScanning();
        }
    }

    activateScanning() {
        this.setState({isScanning: true});
    }

    deactivateScanning() {
        this.setState({isScanning: false});
    }

    toggleScanning() {
        this.setState({isScanning: !this.state.isScanning});
    }

    toggleLoading() {
        this.setState({isLoading: !this.state.isLoading});
    }

    searchByBarcode(barcode) {
        if (this.props.defaultValues && this.props.defaultValues[barcode] && this.props.list === 'releases') {
            this.props.onReleaseFound(this.props.defaultValues[barcode]);
            return;
        }
        
        if (this.props.defaultValues && this.props.defaultValues[barcode] && this.props.list === 'orders') {
            this.props.onOrderFound(this.props.defaultValues[barcode]);
            return;
        }

        this.toggleLoading();
        
        if (this.props.list === 'releases') {
            http.get(API_HOST + API_ROOT + 'carts/findbybarcode', true, {barcode: barcode})
                .then(response => {
                    let result = response.result;
                    let type = result.type;
                    let item = result.item;
                    
                    if (type === 'release') {
                        if (Array.isArray(item)) {
                            this.props.onMultipleReleasesFound(item, barcode);
                        }
                        
                        if (! Array.isArray(item)) {
                            this.props.onReleaseFound(item);
                        }
                    }
            
                    if (type === 'product') {
                        if (! Array.isArray(item)) {
                            this.props.onProductFound(item);
                        }
                    }
                    
                    this.toggleLoading();
                })
                .catch(error => {
                    if (error.message === 'item_not_found') {
                        
                        this.props.onReleaseNotFound(barcode);
                        
                        if (this.props.showErrors) {
                            ui.notify('Release/Product not found', 'error');
                        }
                        
                        this.toggleLoading();
                    }
                })
                .catch(error => handleTokenErrors(error));
        }
        
        if (this.props.list === 'orders') {
            http.get(API_HOST + API_ROOT + 'orders/findbybarcode', true, {barcode: barcode})
                .then(response => {
                    let result = response.result;
    
                    if (result.length > 1) {
                        this.props.onMultipleOrdersFound(result, barcode);
                    }
            
                    if (result.length === 1) {
                        this.props.onOrderFound(result);
                    }
            
                    this.toggleLoading();
                })
                .catch(error => {
                    if (error.message === 'order_not_found') {
                        this.props.onOrderNotFound(barcode);
                
                        if (this.props.showErrors) {
                            ui.notify('Order not found', 'error');
                        }
                
                        this.toggleLoading();
                    }
                })
                .catch(error => handleTokenErrors(error));
        }
    }

    getScanContent() {
        if (this.state.isLoading) {
            return <Loader isLoading={true}/>;
        }

        if (this.state.isScanning) {
            return (
                <Form showSubmitButton={false} onSubmit={data => this.searchByBarcode(data.barcode)}
                      clearOnSubmit={true}>
                    <TextInput name={'barcode'} placeholder={'Scan your barcode'} focus={true}
                               onBlur={this.deactivateScanning.bind(this)} modifiers={['center', 'negative']}/>
                </Form>
            );
        }

        return (
            <Button text="Activate barcode scanner" modifiers={['stretch', 'transparent']}/>
        );
    }

    render() {
        return (
            <div className={'barcode-scanner' + (this.state.isScanning ? ' barcode-scanner--active' : '')}
                 onClick={this.activateScanning.bind(this)}>
                {this.getScanContent()}
            </div>
        );
    }
}

export default BarcodeScanner;