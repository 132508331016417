export default {

  get(key) {
    let m = document.cookie.match(new RegExp(key + '=[a-zA-Z0-9.()=|%/]+($|;)', 'g'));
    if (!m || !m[0]) {
      return null;
    } else {
      return decodeURI(m[0].substring(key.length + 1, m[0].length).replace(';', '')) || null;
    }
  },

  set(key, value) {
    let ttl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    let expires = 'expires=Fri, 31 Dec 9999 23:59:59 GMT';
    if (ttl) {
      expires = 'expires=' + ttl.toUTCString();
    }

    document.cookie = [key + '=' + encodeURI(value), expires, 'path=/'].join('; ');
  }
};