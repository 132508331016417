import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

export default class InputStack extends React.Component {

  static defaultProps = {
    childRefs: [],
    title: null,
    modifiers: null,
    renderErrorMessage: () => {}
  };

  constructor(props) {

    super(props);

    this.childRefs = [];

    if (this.hasMultipleChildren()) {
      this.props.children.forEach(() => {
        this.childRefs.push(React.createRef());
      });

    } else {
      this.childRefs.push(React.createRef());
    }
  }

  handleSubmit(data) {

    if (this.hasMultipleChildren()) {
      this.props.children.forEach((child, i) => {
        this.childRefs[i].current.handleSubmit(data);
      });
    }

    this.childRefs[0].current.handleSubmit(data);
  }

  hasMultipleChildren() {

    return this.props.children.length;
  }

  getChildren() {

    if (this.hasMultipleChildren()) {

      return this.props.children.map((child, i) => {
        const TagName = child.type;
        return (
          <div key={i} className="input-stack__item">
            <TagName ref={this.childRefs[i]} {...child.props} renderErrorMessage={this.props.renderErrorMessage} />
            {this.props.renderErrorMessage(child.props.name)}
          </div>
        );
      });
    }

    const TagName = this.props.children.type;
    return (
      <div className="input-stack__item">
        <TagName ref={this.childRefs[0]} {...this.props.children.props} renderErrorMessage={this.props.renderErrorMessage} />
        {this.props.renderErrorMessage(this.props.children.props.name)}
      </div>
    );
  }

  render() {

    let title;

    if (this.props.title) {
      title = (
        <div className="input-stack__header">
          {this.props.title}
        </div>
      );
    }

    return (
      <div className={'input-stack'+modifiers('input-stack', this.props.modifiers)}>
        {title}
        {this.getChildren()}
      </div>
    );
  }
}