export default {
  notify(text, type = 'success') {

    let notificationEl = document.createElement('div');
    notificationEl.classList.add('notification');
    notificationEl.classList.add('notification--'+type);
    notificationEl.textContent = text;

    document.body.appendChild(notificationEl);

    setTimeout(() => notificationEl.classList.add('notification--hide'), 2000);
  }
};