import React from 'react';
import ReactDateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css'
import IconButton from "../icon-button";

class DateRangePicker extends React.Component
{
  static defaultProps = {
    value: null,
    onDatesChanged: () => {},
    onClear: () => {}
  };

  onSelect(dates) {

    this.props.onDatesChanged(dates);
  }

  render() {

    return (
      <div>
        <ReactDateRangePicker
          onSelect={dates =>this.onSelect(dates)}
          value={this.props.value}
          firstOfWeek={1}
        />
        <IconButton iconType={'delete'} onClick={e => this.props.onClear()}/>
      </div>
    );

  }
}

export default DateRangePicker;