export const price = (value) => {
    let parts = value.toString().split(".");

    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return '€ ' + numberPart.replace(thousands, " ") + (decimalPart ? "," + decimalPart : ",00");
};

export const date = (timestamp) => {
    let date = new Date(timestamp * 1000);

    let day = date.getDate();
    let month = date.getMonth() + 1;

    return leadingZero(day) + '.' + leadingZero(month) + '.' + date.getFullYear().toString().substr(2, 2);
};

export const time = (timestamp) => {
    let date = new Date(timestamp * 1000);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    return leadingZero(hours) + ':' + leadingZero(minutes);
};

export const dateTime = (timestamp) => {
    return date(timestamp) + ' ' + time(timestamp);
};

export const leadingZero = (number) => {
    return (number >= 10 ? number : '0' + number);
};