import store from "../store";

export const handleTokenErrors = error => {

  if (error.message === 'expired_jwt') {
    store.dispatch({ type: 'INVALID_TOKEN' });
  }

  if (error.message === 'invalid_jwt') {
    store.dispatch({ type: 'REFRESH_EXPIRED' });
  }

  if (error.message === 'buyable_not_found') {
    window.location.reload(false);
  }
};