import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import ui from "../../../../util/ui";
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";

class GiftcardCreate extends React.Component {

  static defaultProps = {
    onCancel: () => {},
    onSave: () => {}
  };

  constructor(props) {

    super(props);

    this.form = React.createRef();
  }

  validateForm(form, data) {

    validateForm(form, data, {
      value: ['required']
    });
  }

  onSubmit(giftcardData) {

    http.post(API_HOST+API_ROOT+'giftcards', giftcardData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      ui.notify('There was an error, giftcard couldn\'t be created');
    });
  }

  render() {

    return (
      <Form
        ref={this.form}
        validate={(form, data) => this.validateForm(form, data)}
        onSubmit={(data) => this.onSubmit(data)}
        showSubmitButton={false}
      >
        <TextInput name="value" label="Value" type="number" showRequiredIndicator={true} />
        <TextInput name="from" label="From" />
        <TextInput name="to" label="To" />
        <TextInput name="custom_message" label="Custom message" />
      </Form>
    );
  }
}

export default GiftcardCreate;