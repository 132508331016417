import React from 'react';
import {photoSrc} from "../../../../util/media";
import {date, price} from "../../../../util/formatting";

import IconLabel from "../../../atoms/icon-label";
import Label from "../../../atoms/label";
import StatusLabel from "../../../atoms/status-label";

class ProductVariationCompactItem extends React.Component {

    static defaultProps = {
        model: {}
    };

    render() {
        return (
            <div className="list-item">
                <div className="list-item__thumb">
                    <img src={photoSrc(this.props.model.product.thumbnail_url)} alt={this.props.model.product.name}/>
                </div>

                <div
                    className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-5">
                    {this.props.model.product.name} <StatusLabel modifiers={['error', 'compact']} title={this.props.model.name}/><br/>
                    {this.props.model.product.artist ? this.props.model.product.artist.name : ''}<br/>
                    {price(this.props.model.price)}
                </div>

                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    Barcode: {this.props.model.barcode}<br/>

                    {this.props.model.is_preorderable && <div><Label title="Preorderable"/></div>}
                </div>
                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    Offline stock: {this.props.model.stock_offline ? this.props.model.stock_offline : 0}<br/>
                    Online stock: {this.props.model.stock ? this.props.model.stock : 0}
                </div>
            </div>
        );
    }
}

export default ProductVariationCompactItem;