import React from "react";

import ModuleView from "../../module-view/index";
import OrderIndex from "../order-index/index";
import OrderView from "../order-view/index";
import Popup from "../../popup";
import IconButton from "../../../atoms/icon-button";
import Button from "../../../atoms/button";
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import ui from "../../../../util/ui";
import orderView from "../order-view/index";

class OrderModule extends ModuleView {

    constructor(props) {

        super(props);

        this.views = {
            index: <OrderIndex onOrderView={order => this.openPopup('viewOrder', order, order.id)}/>,
        };

        this.popups = {
            viewOrder: (order) => {
                let checkoutButton = order.fulfillment_method === 1 && order.post_payment_status !== 3 && (
                    <Button
                        key={'checkout'}
                        text={'Checkout'}
                        onClick={() => {
                            this.checkoutOrder(order, order.id);
                        }}
                        modifiers={'small'}
                    />
                );

                return (
                    <Popup
                        key={order.id}
                        title={'Order ' + order.id}
                        toggle={true}
                        header={checkoutButton}
                    >
                        <OrderView order={order}/>
                    </Popup>
                );
            }
        };

        this.state = {
            view: this.views.index
        };
    }

    checkoutOrder(order, orderId) {
        http
            .post(API_HOST + API_ROOT + 'orders/' + orderId + '/checkout', {}, true)
            .then(response => {
                this.closePopup('viewOrder', orderId);

                ui.notify('Client order checked out');
            })
            .catch(error => {
                ui.notify('There was an error, client order couldn\'t be checked out', 'error');
            })
    }
}

export default OrderModule;