/*
  Constants
 */
import { API_HOST, API_ROOT, http } from "../../util/http";
import store from '../store/index';
import ui from "../../util/ui";
import { handleTokenErrors } from "./errors";

export const ADD_PRODUCT = "inventory/ADD_PRODUCT";
export const UPDATE_PRODUCT = "inventory/UPDATE_PRODUCT";
export const UPDATE_PRODUCT_FORMATS = "inventory/UPDATE_PRODUCT_FORMATS";
export const UPDATE_PRODUCT_LOCATION_AMOUNT = "inventory/UPDATE_PRODUCT_LOCATION_AMOUNT";
export const DELETE_PRODUCT = "inventory/DELETE_PRODUCT";
export const EMPTY_INVENTORY = "inventory/EMPTY_INVENTORY";

/*
  Actions Creators
 */
export function addProduct(product, location = null) {
  return {
    type: ADD_PRODUCT,
    payload: {
      product: product,
      location: location
    }
  }
}

export function updateProduct(product) {
  return {
    type: UPDATE_PRODUCT,
    payload: {
      product: product
    }
  }
}

export function updateProductFormats(product, formats) {
  return {
    type: UPDATE_PRODUCT_FORMATS,
    payload: {
      product: product,
      formats: formats,
    }
  }
}

export function updateProductLocationAmount(product, location, amount) {
  return {
    type: UPDATE_PRODUCT_LOCATION_AMOUNT,
    payload: {
      product: product,
      location: location,
      amount: amount
    }
  }
}

export function deleteProduct(product) {
  return {
    type: DELETE_PRODUCT,
    payload: {
      product: product
    }
  }
}

export function emptyInventory() {
  return {
    type: EMPTY_INVENTORY,
  }
}

export function checkoutInventory() {

  return (dispatch) => {

    let products = store.getState().inventory.products;

    Object.values(products).forEach(product => {

      product.stock = product.stock ? (product.stock + (product.amount.stock || 0)) : product.amount.stock || 0;
      product.stock_2 = product.stock_2 ? (product.stock_2 + (product.amount.stock_2 || 0)) : product.amount.stock_2 || 0;

      // Update product
      if (product.id) {
        http.patch(API_HOST+API_ROOT+'releases/'+product.id, product, true)
          .catch(error => handleTokenErrors(error));

      } else {
        // Create product
        http.post(API_HOST+API_ROOT+'releases/discogs', product, true)
          .catch(error => handleTokenErrors(error));
      }
    });

    dispatch(emptyInventory());
    ui.notify('Inventory successfully updated');
  }
}

/*
  Initial State
 */
const initialState = {
  products: {},
  price: 0
};

/*
  Reducers
 */
export default function reducer(state = initialState, action) {

  switch (action.type) {

    case ADD_PRODUCT: {

      let newProducts = {...state.products};
      let { product, location } = action.payload;

      if (! product.amount) {
        product.amount = {};
      }

      if (! location) {
        newProducts[product.barcode] = product;
        ui.notify(product.title+' added');

        return {
          ...state,
          products: newProducts
        }
      }

      if (product.amount[location]) {
        product.amount[location] = newProducts[product.barcode].amount[location] + 1;

      } else {
        product.amount[location] = 1;
      }

      newProducts[product.barcode] = product;
      ui.notify(product.title+' updated');

      return {
        ...state,
        products: newProducts
      }
    }

    case UPDATE_PRODUCT: {

      let newProducts = {...state.products};

      let { product } = action.payload;

      newProducts[product.barcode] = product;

      return {
        ...state,
        products: newProducts
      }
    }

    case UPDATE_PRODUCT_FORMATS: {

      let newProducts = {...state.products};

      let { product, formats } = action.payload;

      newProducts[product.barcode].formats = formats;

      return {
        ...state,
        products: newProducts
      }
    }
    case UPDATE_PRODUCT_LOCATION_AMOUNT: {

      let newProducts = {...state.products};

      let { product, location, amount } = action.payload;

      newProducts[product.barcode].amount[location] = parseInt(amount);

      return {
        ...state,
        products: newProducts
      }
    }

    case DELETE_PRODUCT: {

      let newProducts = {...state.products};

      delete newProducts[action.payload.product.barcode];

      return {
        ...state,
        products: newProducts
      }
    }

    case EMPTY_INVENTORY: {

      return {
        ...state,
        products: initialState.products
      }
    }

    default:
      return state;
  }
};