import React from 'react';

import TextInput from "../../atoms/text-input/index";
import PasswordInput from "../../atoms/password-input";
import Form from "../form";

class LoginForm extends React.Component {

  static defaultProps = {
    onSubmit: () => {}
  };

  handleSubmit(data) {

    this.props.onSubmit(data);
  }

  render() {

    return (
      <Form onSubmit={(data) => this.handleSubmit(data)} submitButtonText="Login">
        <TextInput name="email" label="Email Address" showRequiredIndicator={true} />
        <PasswordInput name="password" label="Password" showRequiredIndicator={true} />
      </Form>
    );
  }
}

export default LoginForm;