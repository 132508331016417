import React from 'react';
import { price, dateTime } from "../../../../util/formatting";
import { orderLabelModifier } from "../../../../util/label-colors";

import StatusLabel from "../../../atoms/status-label/index";
import Icon from "../../../atoms/icon";

class OrderListItem extends React.Component {

  static defaultProps = {
    model: {}
  };

  render() {

    let hasPreorders;
    let doubleOrders;

    if (this.props.model.has_preorder) {
      hasPreorders = <span>&#9679;</span>
    }

    let type = <Icon type={'storeFront'} />;

    if (this.props.model.type === 1) {
      type = <Icon type={'cart'} />;
    }
    
    let referrer = null;
    
    if (this.props.model.referrer) {
      referrer = <Icon type={'linked'} modifiers={'transparent'}/>
    }

    let fulfillmentmethod = <span>&nbsp;</span>;

    if (this.props.model.hr_fulfillment_method.toLowerCase() === 'pickup' || this.props.model.hr_fulfillment_method.toLowerCase() === 'shipping') {
      fulfillmentmethod = <StatusLabel title={this.props.model.hr_fulfillment_method} modifiers={'info'} />
    }

    let items = [];

    if (this.props.model.order_releases) {
      this.props.model.order_releases.forEach((orderRelease, key) => {
        items.push(<li key={key+'-release'}><b>{orderRelease.release.subtitle}</b> - {orderRelease.release.title} <StatusLabel modifiers={['error', 'compact']} title={orderRelease.release.formats_string}/></li>);
      });
    }

    if (this.props.model.giftcards) {
      this.props.model.giftcards.forEach((giftcard, key) => {
        items.push(<li key={key+'-giftcard'}>{giftcard.title} - {giftcard.subtitle}</li>);
      });
    }

    if (this.props.model.order_product_variations) {
      this.props.model.order_product_variations.forEach((productVariation, key) => {
        items.push(<li key={key + '-productVariation'}>{productVariation.product_variation.product.name} <StatusLabel modifiers={['error', 'compact']} title={productVariation.product_variation.name}/></li>);
      });
    }

    if (this.props.model.custom_products) {
      this.props.model.custom_products.forEach((customProduct, key) => {
        items.push(<li key={key+'-customProduct'}>{customProduct.title} - {customProduct.subtitle}</li>);
      });
    }

    if (this.props.model.order_client_orders) {
      this.props.model.order_client_orders.forEach((orderClientOrder, key) => {
        items.push(<li key={key+'-clientOrder'}>{orderClientOrder.client_order.title} - {orderClientOrder.client_order.subtitle}</li>);
      });
    }

    if (this.props.model.has_double_orders) {
      doubleOrders = <StatusLabel title="!" modifiers={'error'} />;
    }

    return (
      <React.Fragment>
        <div className="list-item">
          <span className="list-item__column list-item__column--clean">
            {hasPreorders} {this.props.model.id}
          </span>
          <span className="list-item__column list-item__column--span-2">
            {dateTime(this.props.model.created)}
          </span>
          <span className="list-item__column list-item__column--span-7">
            <ul className="list-item__column-list">
              {items}
            </ul>
          </span>
          <span className="list-item__column list-item__column--span-2 list-item__column--no-wrap">
            {price(this.props.model.total_price)}
          </span>
          <div className="list-item__column">
            {type}
          </div>
          <span className="list-item__column list-item__column--span-2 list-item__column--clean">
            {fulfillmentmethod}
          </span>
          <span className="list-item__column list-item__column--span-1 list-item__column--clean">
            {doubleOrders}
            {referrer}
          </span>
          <span className="list-item__column list-item__column--span-2">
            <StatusLabel title={this.props.model.hr_status} modifiers={orderLabelModifier(this.props.model.hr_status)} />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderListItem;