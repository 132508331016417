import connect from "react-redux/es/connect/connect";
import {abortController, updateList} from "../../../state/modules/resources";

import React from 'react';

import ActionInput from "../action-input";
import IconButton from "../../atoms/icon-button";

class Search extends React.Component {

  static defaultProps = {
    listId: null,
    modifiers: ['negative'],
    placeholder: 'Type to search...'
  };

  constructor(props) {

    super(props);

    this.state = {
      hasFocus: false,
      renderValue: '',
      value: ''
    };
  }

  componentDidMount() {

    document.addEventListener('keydown', e => this.onKeyActions(e));
  }

  componentWillUnmount() {

    document.removeEventListener('keydown', e => this.onKeyActions(e));
  }

  onKeyActions(e) {

    if ((e.metaKey && e.code === 'KeyF') || (e.ctrlKey && e.code === 'KeyF')) {
      e.preventDefault();
      e.stopPropagation();

      this.focus();
    }
  }

  handleSubmit(data) {

    this.props.onSubmit(data);
  }

  focus() {

    this.setState({
      renderValue: this.state.value,
      hasFocus: true
    });
  }

  blur() {

    this.setState({ hasFocus: false });
  }

  search(value) {

    this.setState({value: value});

    this.props.updateList(this.props.listId, {
      search: value || null
    });
  }

  render() {

    if (! this.state.hasFocus) {
      return (
        <IconButton iconType="search" modifiers="button" onClick={e => this.focus()} />
      );
    }

    return (
      <ActionInput
        default={this.state.renderValue}
        focus={true}
        placeholder={this.props.placeholder}
        buttonIcon={'search'}
        onSubmit={value => this.search(value)}
        onBlur={e => this.blur()}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);