import React from 'react';

export default class HiddenInput extends React.Component {

  static defaultProps = {
    value: '',
    name: ''
  };

  constructor(props) {

    super(props);

    this.state = {
      id: 'input-'+this.props.name+'-'+Math.random().toString(36).substring(7),
      value: this.props.value
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  getValue() {

    return this.state.value;
  }

  handleSubmit(data) {

    data[this.props.name] = this.getValue();
  }

  render() {

    return (
      <div className={"hidden-input"}>
        <input id={this.state.id} name={this.props.name} className="hidden-input__input" type="hidden" value={this.state.value} />
      </div>
    );
  }
}