import localStorage from "../../util/local-storage";
import { API_HOST, API_ROOT, http } from "../../util/http";
import { startLoading, stopLoading } from "./utils";
import { setModules } from "./modules";
import { minimalModules } from "../../util/modules";
import cookie from "../../util/cookie";
import {handleTokenErrors} from "./errors";

/*
  Constants
 */
export const AUTHENTICATE_SUCCESS = "auth/AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAILED = "auth/AUTHENTICATE_FAILED";
export const UNSET_USER = "auth/UNSET_USER";
export const REFRESHING_TOKEN = "auth/REFRESHING_TOKEN";
export const TOKEN_REFRESHED = "auth/TOKEN_REFRESHED";
export const INVALID_TOKEN = "auth/INVALID_TOKEN";
export const ME = "auth/ME";

/*
  Action Creators
 */
export function setToken(accessToken, refreshToken) {

  localStorage.set('ACCESS_TOKEN', accessToken);
  cookie.set('refreshToken', refreshToken);

  return {
    type: AUTHENTICATE_SUCCESS,
    payload: {
      accessToken: accessToken,
      refreshToken: refreshToken,
      isAuthenticated: true
    }
  };
}

export function unsetToken(error) {

  localStorage.remove('ACCESS_TOKEN');
  cookie.set('refreshToken', '');

  return {
    type: AUTHENTICATE_FAILED,
    payload: {
      error: error
    }
  };
}

export function setMe(me) {

  return {
    type: ME,
    payload: {
      me: me
    }
  }
}

export function logout() {

  localStorage.remove('ACCESS_TOKEN');
  cookie.set('refreshToken', '');

  return {
    type: UNSET_USER,
    payload: {
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false
    }
  }
}

export function authenticate(identifier, password) {

  return (dispatch) => {

    dispatch(startLoading());

    return http.request({
      url: API_HOST+API_ROOT+'authenticate',
      headers: {'Authorization': 'Basic ' + btoa(identifier+':'+password)}
    })
    .then(response => {
      dispatch(setToken(response.result.access_token, response.result.refresh_token));
      dispatch(stopLoading());
    })
    .catch(error => {
      dispatch(unsetToken(error));
      dispatch(stopLoading());
    });

  };
}

export function authorize() {

  return (dispatch) => {

    dispatch(startLoading());

    return http.request({ url: API_HOST+API_ROOT+'authorize' }, true)
      .then(response => {
        dispatch(setToken(localStorage.get('ACCESS_TOKEN'), cookie.get('refreshToken')));
        dispatch(stopLoading());
      })
      .catch(error => {
        dispatch(setToken(localStorage.get('ACCESS_TOKEN'), cookie.get('refreshToken')));
        handleTokenErrors(error);
      });
  };
}

export function refreshingToken() {

  return {
    type: REFRESHING_TOKEN
  }
}

export function tokenRefreshed() {

  return {
    type: TOKEN_REFRESHED
  }
}

export function refreshToken() {

  return (dispatch) => {

    dispatch(startLoading());

    return http.post(API_HOST+API_ROOT+'refresh-token', {
      refresh_token: cookie.get('refreshToken'),
    }, true).then(response => {

      dispatch(setToken(response.result.access_token, response.result.refresh_token));
      dispatch(stopLoading());

    }).catch(error => {

      dispatch(unsetToken());
      dispatch(logout());
      dispatch(stopLoading());
    });

  }
}

export function me() {

  return (dispatch) => {

    dispatch(startLoading());

    return http.request({ url: API_HOST+API_ROOT+'users/me' }, true)
      .then(response => {
        dispatch(setMe(response.result));

        // editor user (minimum access) @TODO improve this
        if (response.result.group === 2) {
          dispatch(setModules(minimalModules));
        }

        dispatch(stopLoading());

      })
      .catch(error => handleTokenErrors(error));
  };
}

/*
  Initial State
 */
const initialState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  error: null,
  tokenIsValid: null,
  pendingRefreshingToken: null,
  me: {}
};

/*
  Reducer
 */
export default function reducer(state = initialState, action) {

  switch (action.type) {

    case AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }
    }

    case AUTHENTICATE_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload.error
      }
    }

    case UNSET_USER: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        accessToken: action.payload.accessToken
      }
    }

    case ME: {
      return {
        ...state,
        me: action.payload.me
      }
    }

    case INVALID_TOKEN: {
      return {
        ...state,
        tokenIsValid: false
      }
    }

    case REFRESHING_TOKEN: {
      return {
        ...state,
        pendingRefreshingToken: true,
        tokenIsValid: false
      }
    }

    case TOKEN_REFRESHED: {
      return {
        ...state,
        pendingRefreshingToken: false
      }
    }

    default:
      return state;

  }
};