import {logout, refreshingToken, refreshToken, tokenRefreshed} from "../modules/auth";
import {stopLoading} from "../modules/utils";

let buffer = [];

export const jwt = store => next => action => {

  buffer.push(action);

  if (action.type === 'INVALID_TOKEN') {

    let currentStore = store.getState();

    if (currentStore.auth && currentStore.auth.accessToken && currentStore.auth.refreshToken) {
      if (!currentStore.auth.pendingRefreshingToken) {

        store.dispatch(refreshingToken());
        store.dispatch(refreshToken()).then(() => {
          store.dispatch(tokenRefreshed());

          let pos = buffer.map(e => e.type).indexOf('INVALID_TOKEN') - 1;

          for (let i = pos; i > -1; i -=1) {
            if (typeof buffer[i] === 'function') {
              store.dispatch({
                type: 'RESEND',
                action: buffer[i](store.dispatch)
              });
              break;
            }
          }

          buffer = [];

        });
      }
    }
  } else if (action.type === 'REFRESH_EXPIRED') {

    buffer = [];
    store.dispatch(logout());
    store.dispatch(stopLoading());

  } else {

    if (buffer.length > 20) {
      buffer.splice(0, buffer.length - 20);
    }
    return next(action);
  }
};