import React from "react";

import ModuleView from "../../module-view/index";
import Popup from "../../popup";
import RegisterIndex from "../register-index";
import RegisterView from "../register-view";
import OrderView from "../../order/order-view";

class RegisterModule extends ModuleView {

  constructor(props) {

    super(props);

    this.views = {
      index:
          <RegisterIndex onRegisterView={register => this.openPopup('viewRegister', register, register.id)} />,
    };

    this.popups = {
      viewRegister: (register) => {
        return (
            <Popup title={register.hid} toggle={true} fullscreen={true}>
              <RegisterView register={register} onOrderClick={order => this.openPopup('viewRegisterOrder', order, order.id)}
              />
            </Popup>
        );
      },
      viewRegisterOrder: (order) => {
        return (
            <Popup title={'Order '+order.id} toggle={true}>
              <OrderView order={order} />
            </Popup>
        );
      }
    };

    this.state = {
      view: this.views.index
    };
  }
}

export default RegisterModule;