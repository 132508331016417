import React from 'react';

let popupSortIndex = 0;

class ModuleView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            view: null,
            popups: [],
            activePopup: null,
            activePopupPosition: {x: 0, y: 0},
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', e => this.keyboardEvents(e));
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', e => this.keyboardEvents(e));
    }

    keyboardEvents(e) {

        if (e.key === "Escape") {

            e.preventDefault();
            e.stopPropagation();

            if (this.state.activePopup) {
                this.closePopup(this.state.activePopup.name, this.state.activePopup.id);
            }
        }
    }

    updateView(viewName) {

        this.setState({view: this.views[viewName]});
    }

    updatePopupPosition(position) {

        this.setState({activePopupPosition: position});
    }

    getPopupId(popupName, popupId = '') {

        return popupName + '_' + popupId;
    }

    focusPopup(popupName, popupId = '') {

        let id = this.getPopupId(popupName, popupId);

        popupSortIndex++;

        let popups = (this.state.popups || []).map(popupData => {
            if (popupData.id === id) {
                popupData.sortIndex = popupSortIndex;
            }
            return popupData;
        });

        this.setState({
            popups: popups,
            activePopup: {
                name: popupName,
                id: popupId
            }
        });
    }

    closePopup(popupName, popupId = '', cb = null) {

        let id = this.getPopupId(popupName, popupId);

        let popups = (this.state.popups || []).filter(popupData => {
            return (popupData.id !== id);
        });

        this.setState({
            popups: popups,
            activePopup: null
        }, () => {
            if (cb) {
                cb();
            }
        });
    }

    openPopup(popupName, props = null, popupId = '', cb = null) {

        let id = this.getPopupId(popupName, popupId);
        let callable = this.popups[popupName];

        popupSortIndex++;

        let popupData = {
            id: id,
            name: popupName,
            dataId: popupId,
            sortIndex: popupSortIndex
        };

        if (!callable) {
            popupData.component = null;
        } else if (!props) {
            popupData.component = callable();
        } else {
            popupData.component = callable(props);
        }

        const popups = (this.state.popups || []).filter(popup => {

            if (popup.name !== popupData.name) {
                return popup;
            }

            if (popup.name === popupData.name && !popup.component.props.toggle) {
                return popup;
            }

        }).filter(popup => popup.id !== id);

        popups.push(popupData);

        this.setState({
            popups: popups,
            activePopup: {
                name: popupData.name,
                id: popupData.dataId
            }
        }, () => {
            if (cb) {
                cb();
            }
        });
    }

    renderPopups() {

        let popups = [];

        (this.state.popups || [])
            .sort((a, b) => {
                if (a.sortIndex > b.sortIndex) {
                    return 1;
                } else if (a.sortIndex < b.sortIndex) {
                    return -1;
                }

                return 0;
            })
            .forEach((popupData, i) => {

                let Tag = popupData.component.type;
                let props = popupData.component.props;

                let hasFocus = (i + 1 === this.state.popups.length);

                popups.push(
                    <Tag
                        {...props}
                        key={popupData.id}
                        focus={hasFocus}
                        defaultPosition={this.state.activePopupPosition}
                        onFocus={() => this.focusPopup(popupData.name, popupData.dataId)}
                        onClose={() => this.closePopup(popupData.name, popupData.dataId)}
                        onDragStop={(position) => {
                            this.updatePopupPosition(position);
                        }}
                    />
                );
            });

        return popups;
    }

    render() {

        let popups = this.renderPopups();

        return (
            <React.Fragment>
                {this.state.view}
                {popups}
            </React.Fragment>
        );
    }
}

export default ModuleView;