import React from 'react';
import {price} from "../../../../util/formatting";
import {photoSrc} from "../../../../util/media";

import IconButton from "../../../atoms/icon-button";
import TextInput from "../../../atoms/text-input";
import ButtonGroup from "../../button-group";
import ui from "../../../../util/ui";
import Form from "../../form";
import {validateForm} from "../../../../util/validation";
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import {handleTokenErrors} from "../../../../state/modules/errors";

class CartItem extends React.Component {

    static defaultProps = {
        model: {},
        selected: false,
        onEdit: () => {
        },
        onCancel: () => {
        },
        onSave: () => {
        },
        onDelete: () => {
        }
    };
    
    constructor(props) {
        super(props);
    
        this.state = {
            price: this.props.model.override_price > 0 ? this.props.model.override_price : this.props.model.buyable.price,
            isEditing: false,
            isEditable: this.props.model.is_editable,
        };
    
        this.priceForm = React.createRef();
    }
    
    validatePriceForm(form, data) {
        validateForm(form, data, {
            override_price: ['required', 'number'],
        });
    }
    
    onEdit() {
        if (! this.state.isEditable) {
            return;
        }
        
        this.props.onEdit(this.props.model.id);

        this.setState({
            isEditing: true,
        });
    }
    
    onCancel() {
        this.props.onCancel(this.props.model.id);
        
        this.setState({
            isEditing: false,
        });
    }
    
    onSubmit(data) {
        if (! this.state.isEditable) {
            return;
        }
        
        let cart = this.props.model.cart;
        let cartItem = this.props.model;
    
        this.props.onSave(cart.id, cartItem, data);
    
        this.setState({
            isEditing: false,
        });
    }
    
    render() {
        if (this.props.model.buyable.product_variation !== undefined) {
            return (
                <div className="list-item">
                    <div className="list-item__thumb">
                        <img src={photoSrc(this.props.model.buyable.thumbnail_url)} alt={this.props.model.buyable.product_variation.name}/>
                    </div>
                    <div
                        className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-5">
                        {this.props.model.buyable.title}<br/>
                        {this.props.model.buyable.subtitle}<br/>
                        {this.props.model.quantity}{this.props.model.buyable.stock_2 !== null &&
                    <span> / {this.props.model.buyable.stock_2}</span>}
                    </div>
                    <div className="list-item__column list-item__column--span-2">
                        <span className={this.props.model.buyable.on_sale || this.props.model.override_price > 0 ? "list-item__price list-item__price--sale" : "list-item__price"}>
                            {this.props.model.original_price && price(this.props.model.original_price)}
                        </span>
                        {this.props.model.buyable.on_sale && !this.props.model.override_price > 0 &&
                            <span>{price(this.props.model.buyable.sale_price)}</span>
                        }
                        {this.props.model.override_price > 0 &&
                            <span className="list-item__price">{price(this.state.price)}</span>
                        }
                    </div>
                    <div className="list-item__column">
                        <IconButton iconType={'delete'} onClick={e => this.props.onDelete(this.props.model)}/>
                    </div>
                </div>
            );
        }

        else {
            return (
                <div className="list-item" onDoubleClick={() => this.onEdit()}>
                    <div className="list-item__thumb">
                        <img src={photoSrc(this.props.model.buyable.thumbnail_url)} alt={this.props.model.buyable.title}/>
                    </div>
                    <div
                        className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-5">
                        {this.props.model.buyable.title}<br/>
                        {this.props.model.buyable.subtitle}<br/>
                        {this.props.model.quantity}{this.props.model.buyable.stock_2 !== null &&
                    <span> / {this.props.model.buyable.stock_2}</span>}
                    </div>
                    <div className="list-item__column list-item__column--span-2">
                        {(!this.state.isEditing || !this.props.selected) &&
                            <span>
                                <span className={this.props.model.buyable.on_sale || this.props.model.override_price > 0 ? "list-item__price list-item__price--sale" : "list-item__price"}>
                                    {this.props.model.original_price && price(this.props.model.original_price)}
                                </span>
                                {this.props.model.buyable.on_sale && !this.props.model.override_price > 0 &&
                                    <span className="list-item__price">{price(this.props.model.buyable.sale_price)}</span>
                                }
                                {this.props.model.override_price > 0 &&
                                    <span className="list-item__price">{price(this.state.price)}</span>
                                }
                            </span>
                        }
                        {this.state.isEditing && this.props.selected &&
                            <Form
                                ref={this.priceForm}
                                validate={(form, data) => this.validatePriceForm(form, data)}
                                onSubmit={(data) => this.onSubmit(data)}
                                showSubmitButton={false}
                            >
                            <TextInput focus={this.props.selected} name="override_price" type="number" label="price" default={this.state.price} showRequiredIndicator={true} />
                          </Form>
                        }
                    </div>
                    <div className="list-item__column">
                        { (!this.state.isEditing || !this.props.selected) &&
                            <ButtonGroup>
                                {this.state.isEditable &&
                                    <IconButton iconType={'edit'} onClick={() => {
                                    this.onEdit()
                                    }}/>
                                }
                                <IconButton iconType={'delete'} onClick={e => this.props.onDelete(this.props.model)}/>
                            </ButtonGroup>
                        }
                        { this.state.isEditing && this.props.selected &&
                            <ButtonGroup>
                                <IconButton iconType={'save'} onClick={() => this.priceForm.current.submit()}/>
                                <IconButton iconType={'close'} onClick={() => this.onCancel()}/>
                            </ButtonGroup>
                        }
                    </div>
                </div>
            );
        }
    }
}

export default CartItem;