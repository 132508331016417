import { combineReducers } from "redux";

import utils from "./utils";
import auth from "./auth";
import modules from "./modules";
import resources from "./resources";
import carts from "./carts";
import inventory from "./inventory";

export default combineReducers({
  utils,
  auth,
  modules,
  resources,
  carts,
  inventory
});