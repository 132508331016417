import React from 'react';
import { price } from "../../../../util/formatting";
import { paymentMethodLabelModifier } from "../../../../util/label-colors";

import StatusLabel from "../../../atoms/status-label/index";

class OrderCompactListItem extends React.Component {

  static defaultProps = {
    model: {},
  };

  getPaymentMethodLabel() {

    let paymentMethodLabel = (
      <StatusLabel title={'Unknown'} />
    );

    if (this.props.model.payment_method) {
      paymentMethodLabel = (
        <StatusLabel title={this.props.model.payment_method} modifiers={paymentMethodLabelModifier(this.props.model.payment_method)} />
      );
    }

    return paymentMethodLabel;
  }

  render() {

    let name = '-';

    if (this.props.model.customer) {
      name = this.props.model.customer.first_name+ ' ' +this.props.model.customer.last_name;
    }

    return (
      <React.Fragment>
        <div className="list-item">
          <span className="list-item__column list-item__column--clean list-item__column--span-3">
            <span className="list-item__title">{this.props.model.order_id}</span>
            {price(this.props.model.total_price)} {this.props.model.formatted_discount && <StatusLabel modifiers={'varia-3'} title={this.props.model.formatted_discount}/>}<br />
            {name}
          </span>
          <span className="list-item__column list-item__column--clean">
            {this.getPaymentMethodLabel()}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCompactListItem;