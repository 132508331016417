import thunk from "redux-thunk";
import rootReducer from '../modules/index';
import { createStore, applyMiddleware } from "redux";

import { authorize } from "../modules/auth";
import { jwt } from "./middleware";

const store = createStore(rootReducer, applyMiddleware(jwt, thunk));

store.dispatch(authorize());

export default store;