import './index.scss';

import React from 'react';

class Loader extends React.Component {

  static defaultProps = {
    isLoading: true,
    size: ''
  };

  render() {

    return (
      <div className={'loader' + (this.props.isLoading ? ' loader--loading' : ' loader--idle')+(this.props.size ? ' loader--'+this.props.size : '')}></div>
    );
  }
}

export default Loader;