import React from 'react';

class CustomProductTypeListItem extends React.Component
{
  static defaultProps = {
    item: {}
  };

  render() {

    if (! this.props.item) {
      return;
    }

    return (
      <div className="list-item">
        <div className="list-item__column">
          {this.props.model.title}
        </div>
        <div className="list-item__column">
          {this.props.model.vat_rate}%
        </div>
      </div>
    );
  }
}

export default CustomProductTypeListItem;