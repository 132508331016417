import React from "react";
import OrderModule from "../components/molecules/order/order-module";
import CustomerModule from "../components/molecules/customer/customer-module";
import InventoryModule from "../components/molecules/inventory/inventory-module";
import CustomerCreate from "../components/molecules/customer/customer-create";
import CartModule from "../components/molecules/cart/cart-module";
import DashboardView from "../components/molecules/dashboard-view";
import RegisterModule from "../components/molecules/register/register-module";

export const modules = {
  carts: {
    title: 'Cash desk',
    icon: 'cart',
    view: <CartModule />
  },
  dashboard: {
    title: 'Dashboard',
    icon: 'house',
    view: <DashboardView />
  },
  register: {
    title: 'Registers',
    icon: 'cashRegister',
    view: <RegisterModule />
  },
  orders: {
    title: 'Orders',
    icon: 'cd',
    view: <OrderModule />
  },
  customers: {
    title: 'Customers',
    icon: 'people',
    view: <CustomerModule />,
  },
  inventory: {
    title: 'Inventory',
    icon: 'cd',
    view: <InventoryModule />,
  },
};

export const minimalModules = {
  customers: {
    title: 'Customers',
    icon: 'people',
    view: <CustomerCreate onProceed={e => alert('Thank you for subscribing')}/>,
  },
};